import React from 'react'

export default function NoMatch() {
    return (
        <div>
            <div style={{
                backgroundColor: '#',
                textAlign: 'center',
                height: '100vh',
                alignItems: 'center',
                verticalAlign: 'middle',
                display: 'flex',
                'flexFlow': 'column'
            }}>
                <h2>404 page not found</h2>
                <p>We are sorry but the page you are looking for does not exist.</p>
            </div>

        </div>
    );
}