import React from 'react'
import axios from 'axios'

import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {connect} from "react-redux";
import { DownloadOutlined } from '@ant-design/icons';

import DeploymentData from "../components/DeploymentData";
import ReactMapGL from "react-map-gl/";
import {styles, token} from "../config";
import {Line} from 'react-chartjs-2';
import fileDownload from 'js-file-download'
import {Marker} from "react-map-gl";
import MdHeart from "react-ionicons/lib/IosDisc";
import {DatePicker, Button, Switch, Spin, Divider, Select} from 'antd';
import Chip from '@material-ui/core/Chip';
import StickyHeadTable from '../components/Table'
import 'antd/dist/antd.css'

import {API} from "../config"
import {WaveLoading} from "react-loadingg";
import EGS_Logo from "../assets/EGS_Logo.png";
import MetaTags from "react-meta-tags";
import moment from "moment-timezone";
import {isMobile} from "react-device-detect";
import 'chartjs-plugin-zoom';

const {RangePicker} = DatePicker;

let FullWidthGrid = (props) => {
    let xLabels = [];
    let yLabelsPressure = [];
    let yLabelsTide = [];
    let xLabelsBat = [];
    let batteryVal = [];
    let minBatVal = [];
    let minValColour = [];

    const convertTimezone = (time) => {
        if (props.data.deployment !== undefined){
            const originTimeZone = props.data.deployment.timezone;
            const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const utcTime = moment.utc(time, 'YYYY-MM-DD HH:mm:ss');
            const originTime = utcTime.tz(originTimeZone).format('YYYY-MM-DD HH:mm:ss');
            const localTime = utcTime.tz(browserTimeZone).format('YYYY-MM-DD HH:mm:ss');
            if (props.tz !== true) {
                return (originTime)
            }
            else{
                return (localTime)
            }
        }else{
            return undefined
        }
    };

    const createChartData = () => {
        if (props.isAdmin && props.data.deployment.deployment_device.ownership_state){
            for (const value of props.data.sensordata) {
                if (value) {
                    xLabels.push(convertTimezone(value.date));
                    yLabelsPressure.push(value.pressure);
                    yLabelsTide.push(value.tide)
                } else {
                    xLabels = null;
                    yLabelsPressure = null;
                    yLabelsTide = null;
                }
            }
        }
        else{
            for (const value of props.data.sensordata) {
                if (value) {
                    xLabels.push(convertTimezone(value.date));
                    // yLabelsPressure.push(value.pressure);
                    yLabelsTide.push(value.tide)
                } else {
                    xLabels = null;
                    yLabelsPressure = null;
                    yLabelsTide = null;
                }
            }
        }

    };

    const createChartDataBat = () => {
        if (props.data.deployment.deployment_device.ownership_state && props.isAdmin){
            const minBat = props.data.deployment.deployment_device.battery_min
            for (const value of props.data.batterydata) {
                if (value) {
                    xLabelsBat.push(convertTimezone(value.date));
                    batteryVal.push(value.voltage);
                    minBatVal.push(minBat);
                    if (value.voltage <= minBat){
                        minValColour.push('rgb(162,39,31)')
                    }
                    else{
                        minValColour.push('rgb(56,131,23)')
                    }

                } else {
                    xLabelsBat = null;
                    batteryVal = null;
                    minBatVal.push(minBat);
                }
            }
        }
        else{
            xLabelsBat = null;
            batteryVal = null;
            minBatVal = null;
        }

    };

    const stateTide = {
        labels: xLabels,
        datasets: [
            {
                label: "Tide(m)",
                lineTension: 0.1,
                backgroundColor: 'rgba(0,0,0,0.4)',
                borderColor: 'rgb(37,37,30)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "round",
                pointBorderColor: 'rgb(66,73,58)',
                pointBackgroundColor: 'rgb(37,37,30)',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgb(66,73,58)',
                pointHoverBorderColor: 'rgb(37,37,30)',
                pointHoverBorderWidth: 2,
                pointRadius: 0.5,
                pointHitRadius: 10,
                legend: {display: false,},
                data: yLabelsTide
            }
        ],
        options: {
            maintainAspectRatio: false,
            zoom: {
                enabled: true,
                mode: 'x',
            },
            pan: {
                enabled: true,
                mode: 'x',
            },
            legend: false,
            scales: {
                xAxes: [{
                    type: 'time',
                    time: {
                        displayFormats: {
                            hour: 'hA'
                        },
                    },
                    ticks: {
                        maxTicksLimit: 12,
                    }
                }],
                yAxes: [{
                    ticks: {
                        maxTicksLimit: 6,
                        callback: function (value, index, values) {
                            return value + "m";
                        }
                    }
                }]
            }
        }
    };

    const statePressure = {
        labels: xLabels,
        lineAtIndex: [2, 4, 8],
        datasets: [
            {
                label: "Pressure (dBar)",
                lineTension: 0.7,
                backgroundColor: 'rgba(0,0,0,0.4)',
                borderColor: 'rgb(37,37,30)',
                borderCapStyle: "round",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "round",
                pointBorderColor: 'rgb(66,73,58)',
                pointBackgroundColor: 'rgb(37,37,30)',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgb(66,73,58)',
                pointHoverBorderColor: 'rgb(37,37,30)',
                pointHoverBorderWidth: 2,
                pointRadius: 0.5,
                pointHitRadius: 10,
                data: yLabelsPressure
            }
        ],
        options: {
            maintainAspectRatio: false,
            zoom: {
                enabled: true,
                mode: 'x',
            },
            pan: {
                enabled: true,
                mode: 'x',
            },
            legend: false,
            scales: {
                xAxes: [{
                    type: 'time',
                    time: {
                        displayFormats: {
                            hour: 'hA'
                        },
                    },
                    ticks: {
                        maxTicksLimit: 12,
                    }
                }],
                yAxes: [{
                    ticks: {
                        maxTicksLimit: 6,
                        callback: function (value, index, values) {
                            return value + "dbar";
                        }
                    }
                }]
            }
        }
    };

    const stateBattery = {
        labels: xLabelsBat,
        lineAtIndex: [2, 4, 8],
        datasets: [
            {
                label: "Battery (voltage)",
                lineTension: 0.5,
                backgroundColor: 'rgb(0,0,0,0)',
                borderColor: 'rgba(65,160,26,0.76)',
                borderCapStyle: "round",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "round",
                pointBorderColor: minValColour,
                pointBackgroundColor: minValColour,
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: minValColour,
                pointHoverBorderColor: minValColour,
                pointHoverBorderWidth: 2,
                pointRadius: 1.5,
                pointHitRadius: 10,
                data: batteryVal
            },{
                label: "Low Battery Warning Threshold",
                lineTension: 0.5,
                backgroundColor: 'rgb(162,39,31, 0)',
                borderColor: 'rgba(166,0,5,0.5)',
                borderCapStyle: "round",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "round",
                pointBorderColor: 'rgba(166,0,5,0)',
                pointBackgroundColor: 'rgba(166,0,5,0.5)',
                pointBorderWidth: 0.5,
                pointHoverRadius: 3,
                pointHoverBackgroundColor: 'rgba(166,0,5,0.5)',
                pointHoverBorderColor: 'rgba(166,0,5,0.5)',
                pointHoverBorderWidth: 2,
                pointRadius: 0.5,
                pointHitRadius: 10,
                data: minBatVal
            }
        ],
        options: {
            maintainAspectRatio: false,
            zoom: {
                enabled: true,
                mode: 'x',
                overScaleMode: 'xy',
            },
            pan: {
                enabled: true,
                mode: 'x',
                overScaleMode: 'xy',

            },
            legend: false,
            elements: {
                line: {
                    tension: 0 // disables bezier curves
                }
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    time: {
                        displayFormats: {
                            hour: 'hA'
                        },
                    },
                    ticks: {
                        maxTicksLimit: 12,
                    }
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: false,
                        maxTicksLimit: 6,
                        callback: function (value, index, values) {
                            return value + "V";
                        }
                    }
                }]
            }
        }
    };

    const checkDataReturn = (array, index, isTrue, isFalse, isTrueProp = true, islastElement = false) => {
        const lastElement = getLast(array, index);
        if (lastElement) {
            if (isTrueProp) {
                if (isTrue === "tide" ){
                    return lastElement[isTrue].toFixed(3)
                }
                return lastElement[isTrue];
            } else if (islastElement) {
                return lastElement;
            } else {
                return isTrue;
            }

        } else {
            return isFalse;
        }
    };

    const getLast = (arr = null, n = null) => {
        const array = arr;
        if (array == null) return void 0;
        if (n === null) return array[array.length - 1];
        return array.slice(Math.max(array.length - n, 0));
    };

    // const batteryPercentage = () => {
    //     if (batteryVal !== null) {
    //         let batteryData = batteryVal
    //         let initialV = localStorage.getItem("initialV");
    //         if (initialV === 'null') {
    //             // console.log("start initialV gen")
    //             for (let i = batteryData.length-1; i > 0 ; i--){
    //                 if ((parseFloat(batteryData[i])-parseFloat(batteryData[i-1])) >= 0.5){
    //                     initialV = parseFloat(batteryData[i]);
    //                     try{
    //                         if (batteryData[i+1] !== undefined){
    //                             if (parseFloat(batteryData[i+1]) >= parseFloat(batteryData[i])){
    //                                 initialV = parseFloat(batteryData[i+1]);
    //                                 break
    //                             }
    //                         }
    //                     }
    //                     catch(error){
    //                         console.log(`issue with retrieving data ${props.data.deployments.deployment_device.title_amended}}- ${error}`)
    //                     }
    //                     initialV = parseFloat(batteryData[i])
    //                     break
    //                 }
    //             }
    //             if (initialV === null){
    //                 initialV = batteryData[0]
    //             }
    //             // console.log(initialV)
    //             localStorage.setItem('initialV', initialV);
    //         }
    //         else{
    //             // console.log(localStorage.getItem("initialV"))
    //             initialV = parseFloat(localStorage.getItem("initialV"));
    //         }
    //
    //         const finalV = batteryData[batteryData.length - 1]
    //         const minVolt = parseFloat(props.data.deployment.deployment_device.battery_min)
    //
    //         let percentage = ((finalV - minVolt) / (initialV - minVolt));
    //             if (percentage < 0) {
    //                 return 0
    //             }
    //             else if (percentage > 1){
    //                 return 100
    //             }
    //             return (percentage * 100).toPrecision(3)
    //     }
    //     return `Not Available`
    // }

    const getTimeZone = () => {
        const originTimeZone = props.data.deployment.timezone;
        const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (props.tz !== true) {
            return (originTimeZone)
        }
        else{
            return (browserTimeZone)
        }
    }

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 0
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
            square: true,
        },
    }));

    const getViewportCentered = (lat, lng) => {
        return {
            width: "100%",
            height: "37vh",
            latitude: parseFloat(lat),
            longitude: parseFloat(lng),
            zoom: 8.5
        }
    }

    const classes = useStyles();
    const NoData = "No Data ";
    return (
        <div className={classes.root}>
            {createChartData()}
            {createChartDataBat()}
            {props.data.sensordata.length !== 0 ? (
                <Grid container spacing={2}>
                    <Grid item xs={12} xl={12}>
                        <Paper className={classes.paper} style={{backgroundColor: "#91d5ff"}}>
                            <div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <h6 className="text-sm-right text-xs-center"> Tide Gauge (m) </h6>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div className="text-sm-right text-xs-center">
                                            {isMobile ? (
                                                <Chip
                                                    size="small"
                                                    label={
                                                        `${convertTimezone(checkDataReturn(props.data.sensordata, null, 'date', NoData))}
                                                        (${getTimeZone()}) -  
                                                        ${checkDataReturn(props.data.sensordata, null, 'tide', NoData)}m
                                                        `
                                                    }
                                                    syle={{padding: '0px 0px', height:"100%"}}
                                                    color={`${checkDataReturn(props.data.sensordata, null, 'primary', 'default', false)}`}
                                                />
                                            ) : (
                                                <Chip
                                                    size="small"
                                                    label={
                                                        `Latest: 
                                                        ${convertTimezone(checkDataReturn(props.data.sensordata, null, 'date', NoData))}
                                                        (${getTimeZone()}) -  
                                                        ${checkDataReturn(props.data.sensordata, null, 'tide', NoData)}m
                                                        `
                                                    }
                                                    syle={{padding: '0px 0px', height:"100%"}}
                                                    color={`${checkDataReturn(props.data.sensordata, null, 'primary', 'default', false)}`}
                                                />
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                        <Paper className={classes.paper} style={{backgroundColor: "#bae7ff"}}>
                            {props.data.TideDataLoading ?
                                <div style={{textAlign: 'center'}}>
                                    {/*<Skeleton active/>*/}
                                    <Spin size='large' tip="Getting New Tide Data ..."/>
                                </div>
                                :
                                <Grid container spacing={2}>
                                    {isMobile ? (
                                        <Line data={stateTide} height={300} options={stateTide.options}/>
                                    ) : (
                                        <>
                                            <Grid item sm={12} lg={7} xl={9}>
                                                <Line data={stateTide} height={300} options={stateTide.options}/>
                                            </Grid>
                                            <Grid item sm={12} lg={5} xl={3}>
                                                <StickyHeadTable
                                                    id='tideTable'
                                                    titles={['Time', 'Tide\u00a0(m)']}
                                                    data={[xLabels, yLabelsTide]}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            }
                        </Paper>
                    </Grid>
                        {(props.isAdmin && props.data.deployment.deployment_device.ownership_state) ? (
                            <Grid item xs={12} xl={12}>
                                <Paper className={classes.paper} style={{backgroundColor: "#91d5ff"}}>
                                    <div>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <h6 className="text-sm-right text-xs-center"> Pressure (dBar) </h6>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <div className="text-sm-right text-xs-center">
                                                    {isMobile ? (
                                                        <Chip
                                                            size="small"
                                                            label={
                                                                `${convertTimezone(checkDataReturn(props.data.sensordata, null, 'date', NoData))}
                                                                (${getTimeZone()}) - 
                                                                ${checkDataReturn(props.data.sensordata, null, 'pressure', NoData)}dBar
                                                                `
                                                            }
                                                            syle={{padding: '0px 0px', height:"100%"}}
                                                            color={`${checkDataReturn(props.data.sensordata, null, 'primary', 'default', false)}`}
                                                        />
                                                    ) : (
                                                        <Chip
                                                            size="small"
                                                            label={
                                                                `Latest: 
                                                                ${convertTimezone(checkDataReturn(props.data.sensordata, null, 'date', NoData))}
                                                                (${getTimeZone()}) - 
                                                                ${checkDataReturn(props.data.sensordata, null, 'pressure', NoData)}dBar
                                                                `
                                                            }
                                                            syle={{padding: '0px 0px', height:"100%"}}
                                                            color={`${checkDataReturn(props.data.sensordata, null, 'primary', 'default', false)}`}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Paper>
                                <Paper className={classes.paper} style={{backgroundColor: "#bae7ff"}}>
                                    {props.data.TideDataLoading ?
                                        <div style={{textAlign: 'center'}}>
                                            {/*<Skeleton active/>*/}
                                            <Spin size='large' tip="Getting New Pressure Data ..."/>
                                        </div>
                                        :
                                        <Grid container spacing={2}>
                                            { isMobile ? (
                                                <Line data={statePressure} height={300} options={statePressure.options}/>
                                            ) : (
                                                <>
                                                    <Grid item sm={12} lg={7} xl={9}>
                                                        <Line data={statePressure} height={300}
                                                              options={statePressure.options}/>
                                                    </Grid>
                                                    <Grid item sm={12} lg={5} xl={3}>
                                                        <StickyHeadTable
                                                            id='pressureTable'
                                                            titles={['Time', 'Pressure\u00a0(dBar)']}
                                                            data={[xLabels, yLabelsPressure]}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    }
                                </Paper>
                            </Grid>
                        ) : null}
                        {(props.isAdmin && props.data.deployment.deployment_device.ownership_state)? (
                            <Grid item xs={12} xl={12}>
                                <Paper className={classes.paper} style={{backgroundColor: "#91d5ff"}}>
                                    <div>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <h6 className="text-sm-right text-xs-center"> Battery (V) </h6>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <div className="text-sm-right text-xs-center">
                                                    {isMobile ? (
                                                        <Chip
                                                            size="small"
                                                            label={
                                                                `${checkDataReturn(xLabelsBat, null, 'date', NoData,false,true)} 
                                                                - ${checkDataReturn(batteryVal, null, 'voltage', NoData,false,true)}V`
                                                            }
                                                            syle={{padding: '0px 0px', height:"100%"}}
                                                            color={`${checkDataReturn(batteryVal, null, 'primary', 'default', false)}`}
                                                        />
                                                    ) : (
                                                        <Chip
                                                            size="small"
                                                            label={
                                                                `Latest: 
                                                                ${checkDataReturn(xLabelsBat, null, 'date', NoData,false,true)}
                                                                (${getTimeZone()}) - 
                                                                ${checkDataReturn(batteryVal, null, 'voltage', NoData,false,true)}V`
                                                            }
                                                            syle={{padding: '0px 0px', height:"100%"}}
                                                            color={`${checkDataReturn(batteryVal, null, 'primary', 'default', false)}`}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Paper>
                                <Paper className={classes.paper} style={{backgroundColor: "#bae7ff"}}>
                                    {props.data.BatteryDataLoading ?
                                        <div style={{textAlign: 'center'}}>
                                            {/*<Skeleton active/>*/}
                                            <Spin size='large' tip="Getting New Battery Data ..."/>
                                        </div>
                                        :
                                        <Line data={stateBattery} height={300} options={stateBattery.options}/>
                                    }
                                </Paper>
                            </Grid>
                        ) : null}
                    {props.data.deployment.deployment_device.ownership_state &&
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Paper className={classes.paper} style={{backgroundColor: "#91d5ff"}}>
                                <h5>Deployment Specification</h5>
                            </Paper>
                            <Paper className={classes.paper} style={{backgroundColor: "#bae7ff"}}>
                                <div style={{padding: "10px 10px", textAlign: 'left', height: '37vh'}}>
                                    <DeploymentData data={props.data.deployment}/>
                                </div>
                            </Paper>
                        </Grid>
                    }
                    <Grid item xs={12}
                          sm={props.data.deployment.deployment_device.ownership_state === true ? 6 : 12}
                          md={props.data.deployment.deployment_device.ownership_state === true ? 8 : 12}
                          lg={props.data.deployment.deployment_device.ownership_state === true ? 9 : 12}
                          xl={props.data.deployment.deployment_device.ownership_state === true ? 10 : 12}
                    >
                        <Paper className={classes.paper} style={{backgroundColor: "#91d5ff"}}>
                            <h5>Location</h5>
                        </Paper>
                        <Paper className={classes.paper} style={{backgroundColor: "#bae7ff"}}>
                            <div>
                                <ReactMapGL
                                    {...getViewportCentered(props.data.deployment.latitude, props.data.deployment.longitude)}
                                    mapStyle={styles.custom}
                                    mapboxApiAccessToken={token}
                                >
                                    <Marker key={props.data.deployment.id}
                                            latitude={parseFloat(props.data.deployment.latitude)}
                                            longitude={parseFloat(props.data.deployment.longitude)}
                                            offsetLeft={-20}
                                            offsetTop={-10}
                                    >
                                        <MdHeart fontSize="20px" color="blue" beat={true}/>
                                    </Marker>
                                </ReactMapGL>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            ) : null
            }
        </div>
    );
};

class DeploymentDetail extends React.Component {

    state = {
        deployment: [],
        sensordata: [],
        datavalues: [],
        fileName: '',
        batterydata: [],
        loading: false,
        downloadView: false,
        timeZoneBrowser: Intl.DateTimeFormat().resolvedOptions().timeZone,
        filetype: true,
        downloadViewTXT: false,
        datavaluestxt: [],
        datavaluescsv: [],
        dates : [],
        tideDateRange: '1day',
        batteryDateRange: '30days',
        TideDataLoading: false,
        dataUpdated: false,
        loadingDeployments: true
    };
    isSensorData = false;
    classes = makeStyles(theme => ({
        root: {
            flexGrow: 0
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }));

    componentDidMount() {
        localStorage.setItem('initialV', null)
        axios.defaults.headers = {
            'Content-Type': "application/json",
            Authorization: `Token ${localStorage.getItem('token')}`
        };
        const deploymentID = this.props.computedMatch.params.deploymentID;
        axios.get(`${API.deployments}${deploymentID}/`)
        .then(res => {
            this.setState({
                deployment: res.data,
                loadingDeployments: false
            });
        });
    }

    handleTideClick = (val)=> {
        document.body.style.cursor='wait';
        setTimeout(() => {
            document.body.style.cursor='default';
            this.setState({
                tideDateRange: val,
                dataUpdated: true
            });
        }, 1000)
    };

    handleBatteryClick = (val)=> {
        document.body.style.cursor='wait';
        setTimeout(() => {
            document.body.style.cursor='default';
            this.setState({
                batteryDateRange: val
            });
        }, 1000)
    };


    componentDidUpdate(prevProps, prevState) {
        try {
            if (((this.state.deployment.length !== 0) && (prevState.deployment !== this.state.deployment)) || (prevProps.timeZone !== this.props.timeZone)) {
                // this.getSensorData()
                localStorage.setItem('initialV', null)
                this.UpdateNewTideData()
                this.UpdateNewBatteryData()
            }
            if ( this.state.dates !== prevState.dates) {
                this.downloadLoad(this.state.dates)
            }
            if (prevState.tideDateRange !== this.state.tideDateRange){
                this.UpdateNewTideData()
            }
            if (prevState.batteryDateRange !== this.state.batteryDateRange){
                this.UpdateNewBatteryData()
            }

        } catch (err) {
            throw err;
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
        this.timer = null;
        clearTimeout(this.TimerBateryData);
        this.TimerBateryData = null;
        // localStorage.removeItem("initialV");
    }

    UpdateNewTideData() {
        this.setState({ TideDataLoading: true }, () => {
            this.isSensorData = true;
            const serverID = this.state.deployment.deployment_device.token;
            axios.get(`${API.sensorData}${serverID}/?date=${this.state.tideDateRange}`)
            .then(res => {
                if (res.data.length === 0) {
                    this.timer = setTimeout(() => this.UpdateNewTideData(), 301000);
                    this.setState({
                        sensordata: [undefined],
                        TideDataLoading: false,
                        dataUpdated: false
                    })
                } else {
                    const dateTime = res.data[res.data.length - 1];
                    const delay = this.getDelayFromDate(dateTime.date)
                    // console.log(`sensor data reload @ ${new Date()} - delay ${delay}`);
                    clearTimeout(this.timer)
                    if (delay > 0 && delay < 360000) {
                        this.timer = setTimeout(() => this.UpdateNewTideData(), delay);
                    } else {
                        this.timer = setTimeout(() => this.UpdateNewTideData(), 301000);
                    }
                    this.setState({
                        sensordata: res.data,
                        TideDataLoading: false,
                        dataUpdated: false
                    })
                }
            });
        })
    }

    UpdateNewBatteryData () {
        this.setState({BatteryDataLoading: true }, () => {
            if (this.state.deployment.deployment_device.ownership_state && this.props.isAdmin) {
                let MA = 'False';
                if (this.state.deployment.deployment_device.modem.manufacturer_name === 'MTX'){
                    MA = 'True';
                }
                let sensorIP = this.state.deployment.deployment_device.sim_1.fixed_ip;
                sensorIP = sensorIP.split('.').join('');
                axios.get(`${API.batteryData}${sensorIP}/?date=${this.state.batteryDateRange}&MA=${MA}`)
                .then(res => {
                    if (res.data.length === 0) {
                        this.TimerBateryData = setTimeout(() => this.UpdateNewBatteryData(), 301000);
                        this.setState({
                            BatteryDataLoading: false,
                            batterydata: [undefined]
                        })
                    } else {
                        const dateTime = res.data[res.data.length - 1];
                        const delay = this.getDelayFromDate(dateTime.date);
                        // console.log(`battery data reload @ ${new Date()} - delay ${delay}`);
                        clearTimeout(this.TimerBateryData);
                        if (delay > 0 && delay < 360000) {
                            this.TimerBateryData = setTimeout(() => this.UpdateNewBatteryData(), delay);
                        } else {
                            this.TimerBateryData = setTimeout(() => this.UpdateNewBatteryData(), 301000);
                        }
                        this.setState({
                            BatteryDataLoading: false,
                            batterydata: res.data
                        })
                    }
                });
            } else {
                this.setState({
                    BatteryDataLoading: false,
                    batterydata: [undefined]
                })
            }
        })
    }
    // getDelayFromDate = (dateValue) =>{
    //     const lastTime = new Date(dateValue);
    //     const nowTime = new Date();
    //     return (new Date(lastTime).getTime() - nowTime.getTime()) + 301000
    // };

    getDelayFromDate = (dateValue) => {
        const lastTime_sensor = new Date(moment.utc(dateValue, 'YYYY-MM-DD HH:mm:ss'));
        const nowTime = new Date(moment.utc());
        const delay_sensor = (new Date(lastTime_sensor).getTime() - nowTime.getTime()) + 301000;
        // console.log(dateValue, lastTime_sensor, nowTime, delay_sensor);
        return delay_sensor;
    };

    handleDates (dateArray) {
        document.body.style.cursor='wait';
        setTimeout(() => {
            this.setState({
                dates: dateArray
            })
            document.body.style.cursor='default';
        }, 1000)
    }

    downloadLoad(dates) {
        const currentDate = new Date().getTime()
        const startDate = new Date(dates[0]).getTime()
        if (startDate > currentDate){
            alert('start date in date range selector cannot be after current date')
        }
        else if (this.state.deployment.length !== 0) {
            this.setState({ downloadView: true }, () => {
                const formatted_date = moment().format('YYYY_MM_DD__HH_mm_ss')// let formatted_date = current_datetime.getFullYear() + "_" + (current_datetime.getMonth() + 1) + "_" + current_datetime.getDate() + "__" + current_datetime.getHours() + "_" + current_datetime.getMinutes() + "_" + current_datetime.getSeconds()
                const string_dates = (dates.join('_to_')).split('/').join('_')
                const string_title = this.state.deployment.deployment_device.title_amended.split(' ').join('_')
                // this.setState({
                //     fileName: `from_${string_dates}_retrieved_on_${formatted_date}_of_${string_title}`
                // });
                const dbname = this.state.deployment.deployment_device.token;
                axios.get(`${API.downloadCSV}${dbname}/?date=${dates[0]}-${dates[1]}`)
                    .then(res => {
                        let dataValTXT = [];
                        let dataCSV = [];
                        const dataset = res.data;
                        if (this.props.isAdmin && this.state.deployment.deployment_device.ownership_state){
                            dataCSV.push(`"Date","Time","Depth(m)","Pressure(dBar)"\n`)
                            for(const data of dataset){
                                const tide = data['Depth(m)'].toFixed(3)
                                const pressure = data['Pressure(dBar)'].toFixed(3)
                                const formatted_date = moment(data.Date, 'DD-MM-YYYY').format('YYYY/MM/DD')
                                const time_fromapi = moment(data.Time, 'HH:mm:ss').format('HH:mm')
                                dataValTXT.push(`${formatted_date} ${time_fromapi},  ${tide}\n`)
                                dataCSV.push(`"${data.Date}","${data.Time}","${tide}","${pressure}"\n`)
                            }
                        }
                        else{
                            dataCSV.push(`"Date","Time","Depth(m)"\n`)
                            for(const data of dataset){
                                const tide = data['Depth(m)'].toFixed(3)
                                const formatted_date = moment(data.Date, 'DD-MM-YYYY').format('YYYY/MM/DD')
                                const time_fromapi = moment(data.Time, 'HH:mm:ss').format('HH:mm')
                                dataValTXT.push(`${formatted_date} ${time_fromapi},  ${tide}\n`)
                                dataCSV.push(`"${data.Date}","${data.Time}","${tide}"\n`)
                            }
                        }
                        this.setState({
                            downloadView: false,
                            datavalues: dataset,
                            datavaluestxt : dataValTXT,
                            datavaluescsv: dataCSV,
                            fileName: `from_${string_dates}_retrieved_on_${formatted_date}_of_${string_title}`
                        });
                    });
            });
        }
    }

    changeFileFormat = (state) => {
        document.body.style.cursor='wait';
        setTimeout(() => {
            document.body.style.cursor='default';
            this.setState({
                filetype: !state
            });
        }, 1000);

    };

    render() {
        return (
            <div>
                <MetaTags>
                    <meta name="description" content="Tide gauge monitoring system" />
                    <meta property="og:title" content="EGS Sensor Monitor" />
                    <meta property="og:image" content={EGS_Logo} />
                </MetaTags>
                {this.state.loadingDeployments ? (
                    <div
                        style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                        }}
                        >
                            <WaveLoading />
                    </div>
                ):(
                    <div>
                        <div style={{padding: "0px 0px"}}>
                            <Paper className={this.classes.paper} style={{backgroundColor: "#91d5ff"}}>
                                <div className="text-xs-center" style={{padding: "15px 15px", textAlign: 'center'}}>
                                    {this.state.loadingDeployments ?
                                        <Spin size='large' tip="Deployment details ..."/>
                                        :
                                        <div>
                                            <h5 >{this.state.deployment.deployment_device.title_amended}</h5>
                                            <Divider key={'choose_sensor_data_range'} dashed="true"> Choose data range for sensor values </Divider>
                                            <Select
                                                value={this.state.tideDateRange}
                                                style={{
                                                    width: 200,
                                                    textAlign: 'center'
                                                }}
                                                onChange={this.handleTideClick}
                                                key='Number of days sensor'
                                                loading={this.state.TideDataLoading}
                                            >
                                                <Select.Option key={1} value={'1day'}>1 day</Select.Option>
                                                <Select.Option key={2} value={'2day'}>2 days</Select.Option>
                                                <Select.Option key={2} value={'3day'}>3 days</Select.Option>
                                                <Select.Option key={2} value={'5day'}>5 days</Select.Option>
                                                <Select.Option key={2} value={'7day'}>7 days</Select.Option>
                                                <Select.Option key={2} value={'30day'}>1 month</Select.Option>
                                            </Select>
                                            {(this.props.isAdmin && this.state.deployment.deployment_device.ownership_state) &&
                                            <>
                                                <Divider key={'choose_battery_data_range'} dashed="true"> Choose data range for battery values </Divider>
                                                <Select
                                                    value={this.state.batteryDateRange}
                                                    style={{
                                                        width: 200,
                                                        textAlign: 'center'
                                                    }}
                                                    onChange={this.handleBatteryClick}
                                                    key='Number of days battery'
                                                    loading={this.state.BatteryDataLoading}
                                                >
                                                    <Select.Option key={1} value={'1day'}>1 day</Select.Option>
                                                    <Select.Option key={2} value={'3day'}>3 days</Select.Option>
                                                    <Select.Option key={2} value={'5day'}>5 days</Select.Option>
                                                    <Select.Option key={2} value={'7day'}>7 days</Select.Option>
                                                    <Select.Option key={2} value={'30day'}>1 month</Select.Option>
                                                    <Select.Option key={2} value={'60day'}>2 month</Select.Option>
                                                    <Select.Option key={2} value={'90day'}>3 month</Select.Option>
                                                    <Select.Option key={2} value={'180day'}>6 months</Select.Option>
                                                    <Select.Option key={2} value={'365day'}>1 year</Select.Option>
                                                </Select>
                                            </>
                                            }
                                        </div>
                                    }
                                </div>
                            </Paper>
                        </div>
                        <div style={{padding: "15px 0 0 0"}}>
                            <FullWidthGrid data={this.state} tz={this.props.timeZone} isAdmin={this.props.isAdmin}/>
                            <div style={{padding: "15px 0px"}}>
                                <Paper className={this.classes.paper} style={{backgroundColor: "#91d5ff"}}>
                                    <div style={{padding: "15px 15px", textAlign: 'center'}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} >
                                                <h5>Download Data</h5>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <p>Choose File type:</p>
                                                <Switch
                                                checked={this.state.filetype}
                                                onChange={() => {this.changeFileFormat(this.state.filetype)}}
                                                checkedChildren=".CSV"
                                                unCheckedChildren=".TXT"
                                                />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <p>Choose dates:</p>
                                                <RangePicker
                                                size={'default'}
                                                format='YYYY/MM/DD'
                                                onChange={(value, dateString) => {this.handleDates(dateString)}}
                                                />
                                            </Grid>
                                            {this.state.filetype ? (
                                                <Grid item xs={12} >
                                                    {this.state.downloadView ? (
                                                        <Button type="primary" loading>
                                                            Generating File
                                                        </Button>
                                                    ) :(
                                                        <div>
                                                            <Button type="primary"
                                                                size={'default'}
                                                                    disabled = {this.state.datavaluescsv.length === 0}
                                                                onClick={()=>{fileDownload( new Blob(this.state.datavaluescsv), `${this.state.fileName}.csv`)}}
                                                            >
                                                                Download
                                                            </Button>
                                                        </div>
                                                    )}
                                                </Grid>
                                            ) : (
                                                <Grid item xs={12} >
                                                    {this.state.downloadView ? (
                                                        <Button type="primary" loading>
                                                            Generating File
                                                        </Button>
                                                    ) : (
                                                        <Button type="primary" icon={<DownloadOutlined />}
                                                            size={'default'}
                                                            onClick={()=>{fileDownload( new Blob(this.state.datavaluestxt), `${this.state.fileName}.txt`)}}
                                                            disabled={this.state.datavaluestxt.length === 0}
                                                        >
                                                            Download
                                                        </Button>
                                                    )}
                                                </Grid>
                                            )}
                                        </Grid>
                                    </div>
                                </Paper>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.token
    }
};

export default connect(mapStateToProps)(DeploymentDetail);