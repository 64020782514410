import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import {connect} from "react-redux";
import axios from "axios";
import {isMobile} from "react-device-detect";
import {API} from "../config";
import {APIEndPointNames} from "../config";

import {
    Transfer,
    Select,
    Button,
    Divider,
    Tag,
    Card,
    message,
    notification,
    Alert,
    Popconfirm,
    Table,
    Spin,
    Progress
} from "antd";
import {CheckCircleTwoTone, CloseCircleTwoTone} from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import fileDownload from "js-file-download";
import 'antd/dist/antd.css';
import {WaveLoading} from "react-loadingg";

// import {APIEndPointNames} from "../config";


class Access extends React.Component {
    consoleOne = React.createRef();
    consoleTwo = React.createRef();

    state ={
        DeploymentLoading: false,
        deployments: [],
        chosenDeployment: null,
        chosenAction: null,
        showResponses: [],
        showResponsesFiles: [],
        showResponsesDataFiles: [],
        selectedKeys: [],
        disabled: false,
        functionSent : false,
        targetKeys: [],
        current : 0,
        downloadFileList: [],
        fileNameList: [],
        sendInstructionLoading: false,
        sendDataRetrieveLoading: false,
        alertMessage: '',
        visible: false,
        currentPercentage: 0,
        currentFile: '',
        fileSizes:[],
        progressSegment: null
    };

    componentDidMount() {
        this._isMounted = true;
        if (this.props.token !== null) {
            this.getData();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.token !== prevProps.token && this._isMounted) {
            this.getData();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    openNotificationWithIcon = (type, message, details) => {
        notification[type]({
            message: message,
            description: details,
            duration: null
            });
    };


    handleSendCommand=()=>{
        const that = this;
        const sensor_make_value = that.state.chosenDeployment.deployment_device.sensor[0].sensor.sensor_make;
        const sensor_model_value = that.state.chosenDeployment.deployment_device.sensor[0].sensor.sensor_model;
        const endpointString = APIEndPointNames[sensor_make_value][sensor_model_value]

        // console.log(sensor_make_value, sensor_model_value, APIEndPointNames[sensor_make_value][sensor_model_value])
        this.setState({ sendInstructionLoading: true }, () => {
            try {
                const url = `${API.sse}/${endpointString}/${this.state.chosenAction}/${this.state.chosenDeployment.deployment_device.modem.imei}/`;
                // const url = 'https://admin.egsasiatide.com/api/sse/TestStreamView'
                let source = new EventSource(url, { headers: {'Authorization': `Token ${this.props.token}`},});
                let count = 0;
                let error = '';
                let fileListNotification = false;
                let fileSize = [];
                source.onopen = function() {
                    console.log('connection to stream has been opened');
                    fileListNotification = false
                };
                source.onerror = function (error) {
                    console.log("Error Reading data " + error);
                    that.setState({
                        alertMessage: `${error}`,
                        sendInstructionLoading: false
                    });
                };
                source.onmessage = function (stream) {
                    let lineList = [];
                    let downloadData = [];
                    if(stream.data){
                        const responseData = stream.data.split("\n");
                        const lineData = JSON.parse(responseData);
                        if (lineData.title === 'Display') {
                            if(!isMobile){
                                that.openNotificationWithIcon('success', lineData.title, lineData.message);
                            }
                            lineList.push(lineData);
                        } else if (lineData.title === 'ERROR') {
                            error = `${lineData.title} : ${lineData.message}`;
                            if (!isMobile){
                                that.openNotificationWithIcon('error', lineData.title, lineData.message);
                            }
                            lineList.push(lineData);
                        } else if (lineData.title === 'Data') {
                            fileSize.push(parseInt(lineData.message.split(' - ')[1].replace('bytes', '')));
                            downloadData.push({
                                key: count.toString(),
                                title: lineData.message,
                                description: `description of content${count + 1}`,
                                disabled: (lineData.response === 'false'),
                            });
                            count = count + 1;
                        } else {
                            lineList.push(lineData);
                        }
                        if (that.state.downloadFileList.length === 100 && !fileListNotification && !isMobile) {
                            // console.log(that.state.fileSizes)
                            fileListNotification = true;
                            that.openNotificationWithIcon('info', "Obtained File List", "Please choose the files you want to download");
                        }
                        that.setState({
                            alertMessage: error,
                            showResponses: [...that.state.showResponses, ...lineList],
                            downloadFileList: [...that.state.downloadFileList, ...downloadData],
                            fileSizes: fileSize,
                            // currentPercentage: percentage
                        }, () => that.scrollToBottomConsoleOne())
                    }
                };
                source.addEventListener('close', (message) =>{
                    source.close();
                    that.setState({
                        sendInstructionLoading: false,
                    })
                })
            }catch(err) {
                this.setState({
                    alertMessage: `${err}`,
                    sendInstructionLoading: false
                });
                console.log("Error Reading data " + err);
            }
        });
    };


    handleChoseDeployment = (index) => {
        // console.log("suspension state")
        // console.log(this.state.deployments[index].deployment_device.suspension)
        this.setState({
            chosenDeployment: this.state.deployments[index]
        });
    };
    handleAction = (index) => {
        this.setState({
            chosenAction : index
        });
    };

    getData(){
        this.setState({ DeploymentLoading: true }, () => {
            axios.defaults.headers = {
                'Content-Type': "application/json",
                Authorization: `Token ${this.props.token}`
            };
            axios.get(API.deploymentsEGSOnly)
            .then(res => {
                if (this._isMounted) {
                    this.setState({
                        DeploymentLoading: false,
                        deployments: res.data,
                    })
                }
            }).catch(err => {
                console.log("Error Reading data " + err);
            });
        });
    };

    handleChange = (nextTargetKeys, direction, moveKeys) => {
        this.setState({
            targetKeys: nextTargetKeys
        });
    };

    handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        this.setState({
            selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys]
        });
    };

    handleScroll = (direction, e) => {
        // console.log('direction:', direction);
        // console.log('target:', e.target);
    };

    handleClearShowResponses = () =>{
        notification.destroy();
        this.setState({
            showResponses: [],
            showResponsesFiles: []
        })
    }

    handleClearShowResponsesData = () =>{
        notification.destroy();
        this.setState({
            showResponsesFiles: []
        })
    }

    handleSendCommandToGetFile =()=> {
        const that = this;

        const sensor_make_value = that.state.chosenDeployment.deployment_device.sensor[0].sensor.sensor_make;
        const sensor_model_value = that.state.chosenDeployment.deployment_device.sensor[0].sensor.sensor_model;
        const endpointString = APIEndPointNames[sensor_make_value][sensor_model_value];

        if (this.state.targetKeys !== undefined){
            this.setState({ sendDataRetrieveLoading: true , showResponsesFiles: []}, () => {
                try {
                    let fileStringList = [];
                    for (let value of this.state.targetKeys) {
                        fileStringList.push(value)
                    }
                    let fileString = fileStringList.join(',');
                    const url = `${API.sse}/${endpointString}/TGGetFileData/${this.state.chosenDeployment.deployment_device.modem.imei}/${fileString}/`;
                    let source = new EventSource(url, { headers: {'Authorization': `Token ${this.props.token}`},});
                    let error = '';
                    let fileDataNotification = false;
                    let fileIndexForDownload = 0;
                    let fileIndexForProgress = 0;
                    let latestFileSize = 0;
                    let blockNumber = 0;
                    source.onopen = function() {
                        console.log('connection to stream has been opened');
                    };
                    source.onerror = function (error) {
                        console.log("Error Reading data " + error);
                        that.setState({
                            alertMessage: `${error}`,
                            sendInstructionLoading: false
                        });
                    };
                    source.onmessage = function (stream) {
                        let lineList = [];
                        let downloadData = [];
                        let percentage = 0;
                        let file = '';
                        let newSegment = null;
                        if(stream.data){
                            const FileData = stream.data.split('\n');
                            const data = JSON.parse(FileData);
                            if (data.title === 'Encoding') {
                                percentage = parseInt(data.message)
                                file = data.response
                            } else if (data.title === 'ERROR') {
                                lineList.push(data);
                                error = `${data.title} : ${data.message}`;
                                if(!isMobile){
                                    that.openNotificationWithIcon('error', data.title, data.message);
                                }
                                that.setState({
                                    alertMessage: error,
                                })
                            } else if (data.title === 'blockReading') {
                                let percentage = ((blockNumber)/latestFileSize)*100
                                const currentBlockString = `Reading... FILE ${that.state.targetKeys[fileIndexForProgress-1]} Block ${(blockNumber/128)+1} of ${Math.ceil(latestFileSize/128)+1}`;
                                lineList.push(data);
                                if (percentage > 100){
                                    percentage = 100
                                }
                                newSegment = {percentage: percentage, string: currentBlockString };
                                blockNumber = blockNumber + 128

                            } else if (data.title === 'fileReading') {
                                blockNumber = 0;
                                newSegment = null;
                                lineList.push(data);
                                latestFileSize = that.state.fileSizes[that.state.targetKeys[fileIndexForProgress]];
                                fileIndexForProgress = fileIndexForProgress + 1
                            } else if (data.title === "Data" ){
                                downloadData.push({
                                    key: data.response,
                                    fileName: data.response,
                                    fileSize: that.state.fileSizes[that.state.targetKeys[fileIndexForDownload]],
                                    link:
                                        <Button
                                            type="link"
                                            onClick={()=>{fileDownload( new Blob([data.message]), data.response)}}
                                            >
                                            Download
                                        </Button>,
                                })
                                fileIndexForDownload = fileIndexForDownload +1
                            }else{
                                newSegment = null;
                                lineList.push(data);
                            }
                        }
                        if (that.state.fileNameList.length === that.state.targetKeys.length && !fileDataNotification && !isMobile) {
                            fileDataNotification = true;
                            that.openNotificationWithIcon('info', "Files are Ready", "Please Click download on the files you want");
                        }
                        that.setState({
                            showResponsesFiles: [...that.state.showResponsesFiles, ...lineList ],
                            fileNameList: [...that.state.fileNameList, ...downloadData ],
                            currentPercentage: percentage,
                            currentFile: file,
                            progressSegment: newSegment
                        }, () => that.scrollToBottomConsoleTwo())
                    };
                    source.addEventListener('close', (message) =>{
                        // console.log(message);
                        // console.log("got event close");
                        source.close();
                        that.setState({
                            sendDataRetrieveLoading: false
                        })
                    })
                }
                catch(err) {
                    this.setState({
                        sendDataRetrieveLoading: false,
                        alertMessage: `${err}`,
                    });
                    console.log("Error Reading data " + err);
                }
            });
        }
    };

    handleVisibleChange = visible => {
        if (!visible) {
            this.setState({ visible });
            return;
        }
        message.destroy();
        this.setState({
            showResponses: []
        })
        if (this.state.chosenAction !== 'TGDeleteMemory') {
            this.handleSendCommand()
        } else {
            this.setState({ visible });
        }
    }

    cancel = () => {
        this.setState({ visible: false });
    };

    FileDownloadColumns = [
        {
            title: 'File Name',
            dataIndex: 'fileName',
            key: 'File Name',
        },
        {
            title: 'File Size (Bytes)',
            dataIndex: 'fileSize',
            key: 'File Size (Bytes)',
        },
        {
            title: 'Download Link',
            dataIndex: 'link',
            key: 'Download Link',
        },
    ];

    scrollToBottomConsoleOne = () => {
        const scroll = this.consoleOne.current.scrollHeight - this.consoleOne.current.clientHeight;
        this.consoleOne.current.scrollTo(0, scroll);
    };

    scrollToBottomConsoleTwo = () => {
        const scroll = this.consoleTwo.current.scrollHeight - this.consoleTwo.current.clientHeight;
        this.consoleTwo.current.scrollTo(0, scroll);
    };

    render(){
        return(
            <div>
                {this.state.DeploymentLoading ?
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <WaveLoading/>
                    </div>
                    :
                    <div>
                        {this.state.alertMessage !== '' &&
                        <div style={{zIndex: 1}}>
                            <Alert type="error" message={this.state.alertMessage} banner/>
                            <br/>
                        </div>
                        }
                        <div style={{
                            minHeight: "100%",
                            padding: '10px',
                            background: '#9ebfeb',
                            height: 'vh'
                        }}
                        >
                            <Divider key={'step1'}>STEP 1 - Choose Modem To Access</Divider>
                            <div style={{padding: '0px 0px 5px 0px', height: 'auto', textAlign: 'center'}}>
                                <div style={{colour: 'white'}}>
                                    <Select
                                        value={this.state.chosenDeployment === null ? 'Choose deployment' : this.state.chosenDeployment.deployment_device.title_amended}
                                        style={{
                                            width: 200,
                                            margin: '0 0px',
                                        }}
                                        onChange={this.handleChoseDeployment}
                                        key='choose deployment'
                                    >
                                        {this.state.deployments.map((deployment, index) => (
                                            <Select.Option
                                                disabled={!deployment.deployment_device.tide_gauge_access}
                                                key={index}
                                                value={index}>{deployment.deployment_device.title_amended}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <Divider key={'step2'}>STEP 2 - Choose Action to perform</Divider>
                            <div style={{padding: '0px 0px 5px 0px', height: 'auto', textAlign: 'center'}}>
                                <Select
                                    value={this.state.chosenAction === null ? 'Choose Action' : this.state.chosenAction}
                                    style={{
                                        width: 200
                                    }}
                                    onChange={this.handleAction}
                                    key='choose deployment'
                                    disabled={this.state.chosenDeployment === null}
                                >
                                    <Select.Option key={1} value={'TGBatteryValue'}>Battery Voltage</Select.Option>
                                    <Select.Option key={2} value={'TGGetFileList'}>Get data List</Select.Option>
                                    {this.props.isAdmin &&
                                    <Select.Option key={3} value={'TGDeleteMemory'}>Delete Memory</Select.Option>
                                    }
                                    {this.props.isAdmin &&
                                    <Select.Option key={4} value={'ModemReset'}>Reboot Modem</Select.Option>
                                    }
                                </Select>
                                <Popconfirm
                                    title="Once files are deleted the Action is Permanent, Please click 'Yes' if you understand the risk"
                                    visible={this.state.visible}
                                    onVisibleChange={this.handleVisibleChange}
                                    onConfirm={() => {
                                        this.handleSendCommand()
                                    }}
                                    onCancel={this.cancel}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    { isMobile ? (
                                        <div style={{padding: isMobile ? '5px' : '10px'}}>
                                            <Button
                                                type="primary"
                                                disabled={this.state.chosenAction === null}
                                                loading={this.state.sendInstructionLoading}
                                            >
                                                Send Instruction
                                            </Button>
                                        </div>
                                    ) : (
                                        <Button
                                            type="primary"
                                            disabled={this.state.chosenAction === null}
                                            loading={this.state.sendInstructionLoading}
                                        >
                                            Send Instruction
                                        </Button>
                                    )}
                                </Popconfirm>
                            </div>
                            <Divider key={'console_1'} dashed="true"> Console </Divider>
                            <div style={{padding: '0px 0px 5px 0px', height: 'auto'}}>
                                {this.state.sendInstructionLoading &&
                                <LinearProgress/>
                                }
                                <Card
                                    size="small"
                                    title={
                                        <>
                                            Communication Log {this.state.sendInstructionLoading && !isMobile ?
                                            <div style={{float: 'right'}}>
                                                CONNECTED <CheckCircleTwoTone
                                                style={{fontSize: '20px', float: 'right', padding: '0px 10px 0px 10px'}}
                                                twoToneColor="#52c41a"/>
                                            </div>
                                            : !this.state.sendInstructionLoading && !isMobile ?
                                            <div style={{float: 'right'}}>
                                                DISCONNECTED <CloseCircleTwoTone
                                                style={{fontSize: '20px', float: 'right', padding: '0px 10px 0px 10px'}}
                                                twoToneColor="#eb2f96"/>
                                            </div>
                                            : null
                                            }
                                        </>
                                    }
                                    extra={
                                        <Button
                                            type="primary"
                                            onClick={this.handleClearShowResponses}
                                            disabled={this.state.showResponses.length === 0}
                                        >
                                            Clear log
                                        </Button>
                                    }
                                    style={{width: 'vw', padding: '5px', boarder: '5px'}}
                                    loading={this.state.showResponses.length === 0}
                                >
                                    <div ref={this.consoleOne} style={{overflowY: 'auto', maxHeight: !isMobile ? '300px' : '150px'}}>
                                        {this.state.showResponses.map((message, index) => (
                                            <>
                                                {message.title === 'Display' ? (
                                                    <h4 style={{color: message.colour, textAlign: 'center'}}>
                                                        {ReactHtmlParser(message.message)}
                                                    </h4>
                                                ) : message.title === 'ERROR' ? (
                                                    <>
                                                        <h4 style={{color: message.colour, textAlign: 'center'}}>
                                                            {message.title} - {ReactHtmlParser(message.message)}
                                                        </h4>
                                                    </>
                                                ) : (
                                                    <>
                                                        {!isMobile ?
                                                            <div style={{padding: '5px'}}>
                                                                <Tag color={message.colour} style={{padding: '2px'}}>
                                                                    {message.title}
                                                                </Tag>
                                                                {ReactHtmlParser(message.message)}
                                                                <Tag style={{float: 'right'}} color={message.ResponseColour}>
                                                                    {ReactHtmlParser(message.response)}
                                                                </Tag>
                                                            </div>
                                                            :
                                                            <div style={{textAlign : 'center'}}>
                                                                {ReactHtmlParser(message.message)}
                                                            </div>
                                                        }
                                                    </>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                    {this.state.sendInstructionLoading && this.state.showResponses.length !== 0 &&
                                    <div style={{textAlign: 'center'}}>
                                        <Spin
                                            tip={`LOADING...   ${this.state.showResponses[this.state.showResponses.length - 1].title}`}/>
                                    </div>
                                    }
                                </Card>
                            </div>
                            {this.state.chosenAction === 'TGGetFileList' &&
                            <div>
                                <Divider key={'step3'}>STEP 3 - Select Files</Divider>
                                <div>
                                    <div style={{textAlign: "center", width : isMobile ? '200px' : 'vw'}}>
                                        <Transfer
                                            dataSource={this.state.downloadFileList}
                                            titles={['Source', 'Target']}
                                            targetKeys={this.state.targetKeys}
                                            selectedKeys={this.selectedKeys}
                                            onChange={this.handleChange}
                                            onSelectChange={this.handleSelectChange}
                                            onScroll={this.handleScroll}
                                            render={item => item.title}
                                            disabled={this.state.chosenAction === null && this.state.chosenAction === 'TGGetFileList'}
                                            oneWay
                                            listStyle={{textAlign: 'left', width: 'vw'}}
                                        />
                                    </div>
                                    <div style={{textAlign: 'center', padding: '10px'}}>
                                        <Button
                                            type="primary"
                                            onClick={this.handleSendCommandToGetFile}
                                            style={{width: '300px'}}
                                            disabled={this.state.targetKeys.length === 0 || this.state.chosenAction === null}
                                            loading={this.state.sendDataRetrieveLoading}
                                        >
                                            Retrieve Data
                                        </Button>
                                    </div>

                                </div>
                                <Divider key={'console_2'} dashed="true"> Console </Divider>
                                <div style={{padding: '0px 0px 5px 0px', height: 'auto'}}>
                                    {this.state.sendDataRetrieveLoading &&
                                    <LinearProgress/>
                                    }
                                    <Card
                                        size="small"
                                        title={
                                            <>
                                                Log for File Retrieval {this.state.sendDataRetrieveLoading && !isMobile ?
                                                    <div style={{float: 'right'}}>
                                                        CONNECTED <CheckCircleTwoTone style={{
                                                        fontSize: '20px',
                                                        float: 'right',
                                                        padding: '0px 10px 0px 10px'
                                                    }} twoToneColor="#52c41a"/>
                                                    </div>
                                                : !this.state.sendDataRetrieveLoading && !isMobile ?
                                                    <div style={{float: 'right'}}>
                                                        DISCONNECTED <CloseCircleTwoTone style={{
                                                        fontSize: '20px',
                                                        float: 'right',
                                                        padding: '0px 10px 0px 10px'
                                                    }} twoToneColor="#eb2f96"/>
                                                    </div>
                                                : null }
                                            </>
                                        }
                                        extra={
                                            <Button
                                                type="primary"
                                                onClick={this.handleClearShowResponsesData}
                                                disabled={this.state.showResponsesFiles.length === 0}
                                            >
                                                Clear log
                                            </Button>
                                        }
                                        style={{width: 'vw', padding: '5px', boarder: '5px'}}
                                        loading={this.state.showResponsesFiles.length === 0}
                                    >
                                        <div ref={this.consoleTwo} style={{overflowY: 'auto',  maxHeight: !isMobile ? '300px' : '150px'}}>
                                            {this.state.showResponsesFiles.map((message, index) => (
                                                <>
                                                    {message.title === 'Display' ? (
                                                        <h4 style={{color: message.colour, textAlign: 'center'}}>
                                                            {ReactHtmlParser(message.message)}
                                                        </h4>
                                                    ) : message.title === 'ERROR' ? (
                                                        <>
                                                            <h4 style={{color: message.colour, textAlign: 'center'}}>
                                                                {message.title} - {ReactHtmlParser(message.message)}
                                                            </h4>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {!isMobile ?
                                                                <div style={{padding: '5px'}}>
                                                                    <Tag color={message.colour} style={{padding: '2px'}}>
                                                                        {message.title}
                                                                    </Tag>
                                                                    {ReactHtmlParser(message.message)}
                                                                    <Tag style={{float: 'right'}} color={message.ResponseColour}>
                                                                        {ReactHtmlParser(message.response)}
                                                                    </Tag>
                                                                </div>
                                                                :
                                                                <div style={{textAlign : 'center'}}>
                                                                    {ReactHtmlParser(message.message)}
                                                                </div>
                                                            }
                                                        </>
                                                    )}
                                                </>
                                            ))}
                                        </div>
                                        {this.state.currentPercentage > 0 ? (
                                            <>
                                                <Progress style={{colour: 'black'}}
                                                          percent={this.state.currentPercentage} status="active"
                                                          showInfo={false}/>
                                                <p style={{fontWeight: 'bold', textAlign: 'center'}}>
                                                    Encoding
                                                    - {this.state.currentFile}... {this.state.currentPercentage}%
                                                </p>
                                            </>
                                        ) : this.state.sendDataRetrieveLoading && this.state.progressSegment !== null ? (
                                            <div style={{textAlign: 'center'}}>
                                                <Progress style={{colour: 'black'}}
                                                          percent={this.state.progressSegment.percentage}
                                                          status="active" showInfo={false}/>
                                                <p style={{fontWeight: 'bold', textAlign: 'center'}}>
                                                    {this.state.progressSegment.string}
                                                </p>
                                            </div>
                                        ) : this.state.sendDataRetrieveLoading && this.state.showResponsesFiles.length !== 0 ? (
                                            <div style={{textAlign: 'center'}}>
                                                <Spin
                                                    tip={`LOADING...   ${this.state.showResponsesFiles[this.state.showResponsesFiles.length - 1].title}`}/>
                                            </div>
                                        ) : null}
                                    </Card>
                                </div>
                                <Divider key={'step4'} orientation="center">STEP 4 - Choose Files to Download</Divider>
                                <div style={{padding: '0px 0px 5px 0px', height: 'auto'}}>
                                    <Table size="small" style={{backgroundColor: `rgba(255, 255, 255, 0.5)`}}
                                           dataSource={this.state.fileNameList} columns={this.FileDownloadColumns}/>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
	return {
		token: state.token
	}
};

export default connect(mapStateToProps)(Access);