import * as React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import BaseRouter from "../routes";
import {PureComponent} from "react";

class Container extends PureComponent {
    render() {
        return (
            <div className="h-100">
                <Router>
                    {/*<Switch>*/}
                    {/*<Route exact path="/" {...this.props} component={Login}/>*/}
                    {/*<Route exact path="/login/" {...this.props} component={Login}/>*/}
                    {/*<CustomLayout {...this.props}>*/}
                    <BaseRouter {...this.props}/>
                    {/*</CustomLayout>*/}
                    {/*</Switch>*/}
                </Router>
            </div>
        )
    }
}

export default Container;