import React from 'react';
import {connect} from "react-redux";
import axios from "axios";
import {Line} from "react-chartjs-2";
import palette from 'google-palette'
import 'chartjs-plugin-annotation';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch'
import {List, Button, Col, Row, Spin, Empty } from 'antd';
import {API} from "../config"
import { WaveLoading } from 'react-loadingg';
import 'chartjs-plugin-annotation';
import EGS_Logo from "../assets/EGS_Logo.png";
import MetaTags from "react-meta-tags";
import moment from "moment-timezone";
import {Chip} from "@material-ui/core";
import {isMobile} from "react-device-detect";

let FullWidthGrid;
FullWidthGrid = (props) => {

    const stateTide = {
        datasets: props.dataset
    };

    const options = {
        animation: false,
        maintainAspectRatio: false,
        legend: false,
        scales: {
            xAxes: [{
                type: 'time',
                time: {
                    displayFormats: {
                        hour: 'hA'
                    },
                },
                ticks: {
                    maxTicksLimit: 6,
                }
            }],
            yAxes: [{
                ticks: {
                    maxTicksLimit: 6,
                    callback: function (value, index, values) {
                        return value + "m";
                    }
                }
            }]
        }
    };

    return (
        <div style={{backgroundColor: '#e7e6eb', padding: '20px 10px', height: 300, borderRadius: '5px'}}>
            {/*{console.log(props)}*/}
            <Line data={stateTide} height={500} options={options}/>
        </div>
    )
};


class Dashboard extends React.Component {
    state = {
        height: window.innerHeight - 290,
        deployments: [],
        colourPalette: [],
        switchState: '1day',
        triggerState: {},
        isMounted: false,
        getDataStatus: false,
        loading: false,
        timeZoneBrowser: Intl.DateTimeFormat().resolvedOptions().timeZone,
        loadingSensorData: {}
    };

    sensorData = [];
    sensorDataChanged = false;
    // isChartRefresh = false;
    datasetData = [];
    shortestTime = null;
    sensorDataProcessed = [];
    triggerPointsHigh = [];
    triggerPointsLow = [];
    intervalID = [];
    SensorRefresh = 0;
    reload = null;

    componentDidMount() {
        try {
            if (this.props.token !== null) {
                this.shortestTime = new Date().getTime();
                this.getData();
            }
        } catch (err) {
            throw err;
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        try {
            if (this.props.token !== prevProps.token) {
                localStorage.setItem('date', this.state.switchState);
                this.getData();
            } else if (((this.state.deployments.length !== 0 && this.sensorDataChanged) || (this.state.switchState !== prevState.switchState) || (prevProps.timeZone !== this.props.timeZone))) { // && !this.sensorSingleRefresh
                this.getSensorData();
            }
            else {
                console.log();
            }
        } catch (err) {
            throw err;
        }

    }

    handleClick = (val)=> {
        // console.log("change to: "+ val)
        localStorage.setItem('date', val)
        this.setState({
            switchState: val
        });
        if (this.state.getDataStatus) {
         // console.log("change to2: "+ val)
            this.getData(val)
        }
    };

    showLowHighTrigger = id => {
		this.setState((prevState) => {
			if (this.triggerPointsHigh[id][0].y === null && this.triggerPointsLow[id][0].y === null ){
			    alert(`The Tide Gauge at ${this.state.deployments[id].deployment_device.title_amended}  does not have a High-Tide or Low-Tide trigger preset` )
                return {
                    triggerState: {...prevState.triggerState, [id]: prevState.triggerState[id]}
                }
			}
		    return {
				triggerState: { ...prevState.triggerState, [id]: !prevState.triggerState[id]}
			}
		});
		if (this.state.getDataStatus) {
			this.updateChartify(id);
		}
	};

    updateChartify(id) {
		if (this.state.triggerState[id]) {
            this.datasetData[id][1] =  {
                label: "Trigger High",
                pointBackgroundColor: 'rgba(0,0,0,0)',
                backgroundColor: 'rgb(0,0,0,0)',
                borderJoinStyle: 'miter',
                lineTension: 0.2,
                borderColor: 'black',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                pointBorderColor: 'black',
                pointBorderWidth: 1,
                pointHoverRadius: 2,
                pointHoverBorderColor: 'black',
                pointHoverBorderWidth: 2,
                pointRadius: 0.5,
                pointHitRadius: 10,
                legend: {display: false,},
                data: null
            }

            this.datasetData[id][2] = {
                label: "Trigger Low",
                pointBackgroundColor: 'rgba(0,0,0,0)',
                backgroundColor: 'rgb(0,0,0,0)',
                borderJoinStyle: 'miter',
                lineTension: 0.2,
                borderColor: 'black',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                pointBorderColor: 'black',
                pointBorderWidth: 1,
                pointHoverRadius: 2,
                pointHoverBorderColor: 'black',
                pointHoverBorderWidth: 2,
                pointRadius: 0.5,
                pointHitRadius: 10,
                legend: {display: false,},
                data: null
            }

		    // this.datasetData[id][1].data = this.triggerPointsHigh[id]
			// this.datasetData[id][2].data = this.triggerPointsLow[id]
		} else {
		    // this.datasetData[id][1].data = null
			// this.datasetData[id][2].data = null

            this.datasetData[id][1] =  {
                label: "Trigger High",
                backgroundColor: 'rgb(0,0,0,0)',
                borderJoinStyle: 'miter',
                lineTension: 0.2,
                borderColor: 'rgba(0,0,0,0.3)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                pointBorderColor: 'rgba(0,0,0,0.3)',
                pointBorderWidth: 1,
                pointHoverRadius: 2,
                pointHoverBorderColor: 'black',
                pointHoverBorderWidth: 2,
                pointRadius: 0.5,
                pointHitRadius: 10,
                legend: {display: false,},
                data: this.triggerPointsHigh[id]
            }

            this.datasetData[id][2] = {
                label: "Trigger Low",
                pointBackgroundColor: 'rgba(0,0,0,0)',
                backgroundColor: 'rgb(0,0,0,0)',
                borderJoinStyle: 'miter',
                lineTension: 0.2,
                borderColor: 'rgba(0,0,0,0.3)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                pointBorderColor: 'rgba(0,0,0,0.3)',
                pointBorderWidth: 1,
                pointHoverRadius: 2,
                pointHoverBorderColor: 'black',
                pointHoverBorderWidth: 2,
                pointRadius: 0.5,
                pointHitRadius: 10,
                legend: {display: false,},
                data: this.triggerPointsLow[id]
            }
		}
	}
    componentWillUnmount() {
        for (let i = 0; i < this.intervalID.length; i++){
            clearTimeout(this.intervalID[i])
        }
        this.intervalID = []
        clearTimeout(this.reload)
        this.reload = null
    }

    getData() {
        this.setState({ loading: true }, () => {
            axios.defaults.headers = {
                'Content-Type': "application/json",
                Authorization: `Token ${this.props.token}`
            };
            axios.get(API.deployments) // , {cancelToken: this.cancelSourceToken}
                .then(res => {
                let state = {};
                let loading = {};
                res.data.map((val, index) => {
                    state[index] = false;
                    loading[index] = false;
                    return null;
                });
                const number = res.data.length;
                // console.log(palette('cb-Dark2', number))
                this.sensorDataChanged = true;
                this.setState({
                    triggerState: state,
                    loading: false,
                    deployments: res.data,
                    // colourPalette: palette('cb-Dark2', number)
                    colourPalette: palette('tol-rainbow', number),
                    loadingSensorData: loading
                })
            })
            .catch(err => {
                console.log(err)
            });
        });
    };

    convertTimezone = (time, index) => {
        const originTimeZone = this.state.deployments[index].timezone;
        const browserTimeZone = this.state.timeZoneBrowser;
        const utcTime = moment.utc(time, 'YYYY-MM-DD HH:mm:ss');
        const originTime = utcTime.tz(originTimeZone).format('YYYY-MM-DD HH:mm:ss');
        const localTime = utcTime.tz(browserTimeZone).format('YYYY-MM-DD HH:mm:ss');
        if (this.props.timeZone !== true) {
            return (originTime)
        }
        else{
            return (localTime)
        }
    }

    getSensorDataIndividual = (sensor, index) => {
        this.SensorRefresh +=1;
        this.sensorDataChanged = false;

        let loadingList = this.state.loadingSensorData;
        loadingList[index] = true;

        this.setState({ loadingSensorData : loadingList}, () => {
            axios.get(`${API.sensorData}${sensor.deployment_device.token}/?date=${this.state.switchState}`).then(res => {
                // console.log(res)
                let dataVal = [];
                let trigValHigh = [];
                let trigValLow = [];
                for (let value of res.data) {
                    dataVal.push({t: this.convertTimezone(value.date, index), y: parseFloat(value.tide).toFixed(3)});
                    trigValHigh.push({t: this.convertTimezone(value.date, index), y: parseFloat(sensor.high_trig).toFixed(3)});
                    trigValLow.push({t: this.convertTimezone(value.date, index), y: parseFloat(sensor.low_trig).toFixed(3)});
                }
                this.sensorDataProcessed[index] = dataVal;
                this.triggerPointsHigh[index] = trigValHigh;
                this.triggerPointsLow[index] = trigValLow;
                if (this.sensorDataProcessed[index][this.sensorDataProcessed[index].length -1] !== undefined) {
                    const non_egs_sensor = this.state.deployments[index].deployment_device.ownership_state
                    const dateTime = this.sensorDataProcessed[index][this.sensorDataProcessed[index].length -1];
                    const delay = this.getDelayFromDate(dateTime.t, non_egs_sensor)
                    // console.log(delay, this.state.deployments[index].deployment_device.title_amended, this.state.colourPalette[index])
                    clearTimeout(this.intervalID[index])
                    if (delay > 0 && delay < 360000) {
                        this.intervalID[index] = setTimeout(() => this.getSensorDataIndividual(sensor, index), delay);
                    }
                    else{
                        this.intervalID[index] = setTimeout(() => this.getSensorDataIndividual(sensor, index), 301000);
                    }
                }
                else{
                    this.intervalID[index] = setTimeout(() => this.getSensorDataIndividual(sensor, index), 301000);
                }
                // this.updateChartify(index)
                this.chartifyDataIndividual (index)
                loadingList[index] = false;
                this.setState({
                    loadingSensorData : loadingList
                })
                // if (this.sensorDataProcessed.length >= this.state.deployments.length) {
                //     // console.log("got all data")
                //     this.chartifyData();
                //     // this.isChartRefresh = true;
                //     this.setState({
                //         deployments: this.state.deployments,
                //     });
                // }
            })
        })
    };
    // getDelayFromDate = (dateValue) =>{
    //     const lastTime = new Date(dateValue);
    //     const nowTime = new Date();
    //     return (new Date(lastTime).getTime() - nowTime.getTime()) + 301000;
    // }

    getDelayFromDate = (dateValue) => {
        const lastTime_sensor = new Date(dateValue);
        const nowTime = new Date(moment.utc());
        const delay_sensor = (new Date(lastTime_sensor).getTime() - nowTime.getTime()) + 301000;
        // console.log(delay_sensor);
        return delay_sensor;
    };


    getSensorData() {
        clearTimeout(this.timer);
        if ((this.state.deployments.length !== 0) && (this.state.deployments.length !== this.sensorData.length)) {
            this.state.deployments.map((sensor, index) => (
                this.getSensorDataIndividual(sensor, index)
            ));
        }
    }

    chartifyDataIndividual (index){
        let dataVal = this.sensorDataProcessed[index];
        const colour = `#${this.state.colourPalette[index]}`;
        let highTrigVal = null;
        let lowTrigVal = null;
        if(this.state.triggerState[index]){
        highTrigVal = this.triggerPointsHigh[index]
        lowTrigVal = this.triggerPointsLow[index]
        }
        this.datasetData[index] = [
         {
            label: `${this.state.deployments[index].deployment_device.title_amended}`,
            pointBackgroundColor: colour,
            backgroundColor: 'rgb(0,0,0,0)',
            borderJoinStyle: 'miter',
            lineTension: 0.5,
            borderColor: colour,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: colour,
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBorderColor: colour,
            pointHoverBorderWidth: 2,
            pointRadius: 0.5,
            pointHitRadius: 10,
            legend: {display: false,},
            data: dataVal
        },
        {
            label: "Trigger High",
            backgroundColor: 'rgb(0,0,0,0)',
            borderJoinStyle: 'miter',
            lineTension: 0.2,
            borderColor: 'rgba(0,0,0,0.3)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: 'rgba(0,0,0,0.3)',
            pointBorderWidth: 1,
            pointHoverRadius: 2,
            pointHoverBorderColor: 'black',
            pointHoverBorderWidth: 2,
            pointRadius: 0.5,
            pointHitRadius: 10,
            legend: {display: false,},
            data: highTrigVal
        },
        {
            label: "Trigger Low",
            pointBackgroundColor: 'rgba(0,0,0,0)',
            backgroundColor: 'rgb(0,0,0,0)',
            borderJoinStyle: 'miter',
            lineTension: 0.2,
            borderColor: 'rgba(0,0,0,0.3)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: 'rgba(0,0,0,0.3)',
            pointBorderWidth: 1,
            pointHoverRadius: 2,
            pointHoverBorderColor: 'black',
            pointHoverBorderWidth: 2,
            pointRadius: 0.5,
            pointHitRadius: 10,
            legend: {display: false,},
            data: lowTrigVal
        }

        ];
        return null
    }

    checkForNullObject (){
        if (this.sensorDataProcessed.length !== 0 && this.state.colourPalette !== null){
            for (let i = 0; i < this.sensorDataProcessed.length; i++){
                // if ((this.sensorDataProcessed[i] === null) || (this.sensorDataProcessed[i][0] === undefined) ) {
                if (this.sensorDataProcessed[i] === null) {
                    console.log(`sensor data at ${i} is not available`)
                    return false
                }
            }
            return true
        }
        return false
    };

    latestDataSensor(index){
        try{
            const latestSensorValue = parseFloat(this.sensorDataProcessed[index][this.sensorDataProcessed[index].length - 1].y);
            const originTimeZone = this.state.deployments[index].timezone
            const browserTimeZone = this.state.timeZoneBrowser
            const latestSensorTime = this.sensorDataProcessed[index][this.sensorDataProcessed[index].length - 1].t
            const originTime = moment(latestSensorTime).tz(originTimeZone).format('YYYY-MM-DD HH:mm:ss')
            const localTime = moment(latestSensorTime).tz(browserTimeZone).format('YYYY-MM-DD HH:mm:ss')
            if (this.props.timeZone !== true) {
            return (<div>Latest data: {latestSensorValue.toFixed(3)}m {originTime} <Chip size="small" label={originTimeZone} /></div>)
            }
            else{
                return (<div>Latest data: {latestSensorValue.toFixed(3)}m  {localTime} <Chip size="small" label={browserTimeZone} /></div>)
            }
        }
        catch(error){
            return <div>Latest data: NA m  NA <Chip size="small" label={'Unknown'} /></div>
        }
    }


    render() {
        return (
            <div>
                <MetaTags>
                    <meta name="description" content="Tide gauge monitoring system" />
                    <meta property="og:title" content="EGS Sensor Monitor" />
                    <meta property="og:image" content={EGS_Logo} />
                </MetaTags>
                {this.state.loading ?
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <WaveLoading />
                    </div>
                    :
                    <div>
                        {(this.checkForNullObject())  ? (
                            <div>
                                <div style={{height: 'auto', textAlign: 'left'}}>
                                    {/*<Grid container spacing={3}>*/}
                                    {/*    <Grid item xs={12} lg={2} md={3}>*/}
                                    {/*        <h6 style={{color: '#001529'}}>Please choose range for data:</h6>*/}
                                    {/*    </Grid>*/}
                                    {/*    <Grid item xs={12} sm={4} md={2}>*/}
                                    {/*        <Button*/}
                                    {/*            style={{ width: '100%', backgroundColor: '#1890ff', color: 'white'}}*/}
                                    {/*            onClick={() => this.handleClick('1day')}*/}
                                    {/*        > 1 Day </Button>*/}
                                    {/*    </Grid>*/}
                                    {/*    <Grid item xs={12} sm={4} md={2}>*/}
                                    {/*        <Button*/}
                                    {/*            style={{ width: '100%', backgroundColor: '#1890ff', color: 'white'}}*/}
                                    {/*            onClick={() => this.handleClick('3day')}*/}
                                    {/*        > 3 Day </Button>*/}
                                    {/*    </Grid>*/}
                                    {/*    <Grid item xs={12} sm={4} md={2}>*/}
                                    {/*        <Button*/}
                                    {/*            style={{ width: '100%', backgroundColor: '#1890ff', color: 'white'}}*/}
                                    {/*            onClick={() => this.handleClick('7day')}*/}
                                    {/*        > 7 Day </Button>*/}
                                    {/*    </Grid>*/}
                                    {/*</Grid>*/}
                                    <Grid container spacing={3} >
                                        {!isMobile &&
                                            <Grid item xs={12} lg={2} md={3}>
                                                <h6 style={{color: '#001529', alignItems: "center", justifyContent: "center" }}>Please choose range for data:</h6>
                                            </Grid>
                                        }
                                        <Grid item xs={4} sm={4} md={2}>
                                            <Button
                                                style={{ width: '100%', backgroundColor: this.state.switchState === '1day' ? '#ff5154' :'#1890ff', color: 'white'}}
                                                onClick={() => this.handleClick('1day')}
                                                disabled={this.state.switchState === '1day'}
                                            > 1 Day </Button>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={2}>
                                            <Button
                                                style={{ width: '100%', backgroundColor: this.state.switchState === '3day' ? '#ff5154' :'#1890ff', color: 'white'}}
                                                onClick={() => this.handleClick('3day')}
                                                disabled={this.state.switchState === '3day'}
                                            > 3 Day </Button>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={2}>
                                            <Button
                                                style={{ width: '100%', backgroundColor: this.state.switchState === '7day' ? '#ff5154' :'#1890ff', color: 'white'}}
                                                onClick={() => this.handleClick('7day')}
                                                disabled={this.state.switchState === '7day'}
                                            > 7 Day </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={{height: 10}}>
                                </div>
                                <List
                                    grid={{
                                        gutter: 14,
                                        xs: 1,
                                        sm: 1,
                                        md: 1,
                                        lg: 2,
                                        xl: 2,
                                        xxl: 4,
                                    }}
                                    dataSource={this.state.deployments}
                                    renderItem={(item, index) => (
                                        <List.Item>
                                            <div
                                                style={{
                                                    backgroundColor: `#${this.state.colourPalette[index]}`,
                                                    padding: '2px 2px',
                                                    borderRadius: '5px'
                                                }}
                                            >
                                                <Row>
                                                    <Col span={20}>
                                                        <h6 style ={{
                                                            padding: '3px 0px 2px 0px',
                                                            textAlign: 'center',
                                                            color: 'white'
                                                        }}>
                                                            {item.deployment_device.title_amended}
                                                        </h6>
                                                    </Col>
                                                    <Col span={4}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center"
                                                            }}
                                                        >
                                                            <Switch checked={this.state.triggerState[index]}
														        onChange = {() => this.showLowHighTrigger(index)}
														        value = "triggerState"
                                                                color="default"
                                                                inputProps={{ 'aria-label': 'checkbox with default color' }}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <div style ={{
                                                        padding: '0px 0px 10px 0px',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontStyle: 'italic'
                                                    }}>
                                                        {this.latestDataSensor(index)}
                                                    </div>
                                                </Row>
                                                {this.state.loadingSensorData[index] ? (
                                                    <div
                                                        style={{
                                                            backgroundColor: '#e7e6eb',
                                                            padding: '20px 10px',
                                                            height: 300,
                                                            borderRadius: '5px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <Spin tip="Loading Chart Data..."/>
                                                    </div>
                                                ): (this.sensorDataProcessed.length >= this.state.deployments.length && this.sensorDataProcessed[index].length === 0 ) ? (
                                                    <div
                                                        style={{
                                                            backgroundColor: '#e7e6eb',
                                                            padding: '20px 10px',
                                                            height: 300,
                                                            borderRadius: '5px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <Empty />
                                                    </div>
                                                ):(
                                                    <FullWidthGrid
                                                        dataset={this.datasetData[index]}
                                                        title={item.deployment_device.title_amended}
                                                        colour={`#${this.state.colourPalette[index]}`}
                                                    />
                                                )}

                                            </div>
                                        </List.Item>
                                    )}
                                />
                            </div>
                        ) : (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <WaveLoading />
                        </div>
                        )}
                    </div>
                }
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        token: state.token
    }
};

export default connect(mapStateToProps)(Dashboard);