import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {applyMiddleware, compose, createStore} from 'redux';
import reducer from './store/reducers/auth';
import thunk from "redux-thunk";
import {Provider} from 'react-redux'
// import {usePromiseTracker} from "react-promise-tracker";
// import Loader from 'react-loader-spinner';

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhances(
    applyMiddleware(thunk)
));
// const LoadingIndicator = props => {
//     const {promiseInProgress} = usePromiseTracker();
//     return (
//         promiseInProgress &&
//         <div
//             style={{
//                 width: "100%",
//                 height: "100",
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center"
//             }}
//         >
//             <Loader type="ThreeDots" color="#2BAD60" height="100" width="100"/>
//         </div>
//     );
// };

// const app = (
//     <Provider store={store}>
//         <App/>
//         <LoadingIndicator/>
//     </Provider>
// );

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>, document.getElementById('root'),
);
serviceWorker.unregister();