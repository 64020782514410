export const token = 'pk.eyJ1IjoiZWdzc3VwZXJhZG1pbiIsImEiOiJjazI0Mm9vZTAwcTB1M2NtcXYxbjZyN3o1In0.lgive5kRRPj3esZ3aZas5w';

export const styles = {
  londonCycle: 'mapbox://styles/alex3165/cj2hv9v4y00242slphcyk9oca',
  light: 'mapbox://styles/mapbox/light-v9',
  dark: 'mapbox://styles/mapbox/dark-v9',
  basic: 'mapbox://styles/mapbox/basic-v9',
  streets: 'mapbox://styles/mapbox/streets-v9',
  outdoor: 'mapbox://styles/mapbox/outdoors-v10',
  custom: 'mapbox://styles/egssuperadmin/ck2cqy0430pr21cpyz7tl505u'
};

/*
export const API = {
    singleSensorData: "https://admin.egsasiatide.com/api/singleSensorData/",
    sensorData: "https://admin.egsasiatide.com/api/sensorData/",
    sensorDataInRange: "https://admin.egsasiatide.com/api/sensorDataInRange/",
    deployments : "https://admin.egsasiatide.com/api/deployment/",
    deploymentsEGSOnly : "https://admin.egsasiatide.com/api/deployment_egs_only/",
    batteryData: "https://admin.egsasiatide.com/api/batterydata/",
    downloadCSV: "https://admin.egsasiatide.com/api/csvsensordata/",
    login: "https://admin.egsasiatide.com/rest-auth/login/",
    signup: "https://admin.egsasiatide.com/rest-auth/registration/",
    notification: "https://admin.egsasiatide.com/api/notificationData/",
    maintenanceData: "https://admin.egsasiatide.com/api/maintenanceDataView/",
    getFileList: "https://admin.egsasiatide.com/api/sse/TGGetFileList/",
    getTGBatValue: "https://admin.egsasiatide.com/api/sse/TG_battery_value/",
    sse: "https://admin.egsasiatide.com/api/sse/",
    mapBoxStatic: 'https://api.mapbox.com/styles/v1/egssuperadmin/ck2cqy0430pr21cpyz7tl505u/static/'
};
*/


export const API = {
    singleSensorData: "https://admin.egstide.com/api/singleSensorData/",
    sensorData: "https://admin.egstide.com/api/sensorData/",
    sensorDataInRange: "https://admin.egstide.com/api/sensorDataInRange/",
    deployments : "https://admin.egstide.com/api/deployment/",
    deploymentsEGSOnly : "https://admin.egstide.com/api/deployment_egs_only/",
    batteryData: "https://admin.egstide.com/api/batterydata/",
    downloadCSV: "https://admin.egstide.com/api/csvsensordata/",
    login: "https://admin.egstide.com/rest-auth/login/",
    signup: "https://admin.egstide.com/rest-auth/registration/",
    notification: "https://admin.egstide.com/api/notificationData/",
    maintenanceData: "https://admin.egstide.com/api/maintenanceDataView/",
    getFileList: "https://admin.egstide.com/api/sse/TGGetFileList/",
    getTGBatValue: "https://admin.egstide.com/api/sse/TG_battery_value/",
    sse: "https://admin.egstide.com/api/sse/",
    mapBoxStatic: 'https://api.mapbox.com/styles/v1/egssuperadmin/ck2cqy0430pr21cpyz7tl505u/static/'
};


export const APIEndPointNames = {
    Valeport : {
        '740STD' : 'valeport740',
        '740' : 'valeport740',
        '0741PT1D20' : 'valeportTideMaster'
    },
    UNKNOWN : {
        UNKNOWN : null
    },
}

export const mySQLconfig = {
    database: 'sensordata',
    user: 'egs.admin',
    password: 'admin123$',
    host : 'localhost',
    port :''
};