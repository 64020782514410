import axios from 'axios';
import * as actionTypes from './actionTypes';
import {API} from "../../config";


export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
};

export const authSuccess = userData => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: userData.token,
        isAdmin: userData.isAdmin,
        isModemAccessAllowed: userData.isModemAccessAllowed
    }
};

export const authFail = error => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
};

export const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('isModemAccessAllowed');
    // localStorage.removeItem('expirationDate');
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = expirationTime => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000)
    }
};

export const authLogin = (username, password) => {
    return dispatch => {
        dispatch(authStart());
        axios.post(API.login, {
            username: username,
            password: password
        })
            .then(res => {
                const token = res.data.key;
                const isAdmin = res.data.user.isAdmin;
                const isModemAccessAllowed = res.data.user.isModemAccessAllowed;
                const userData = {
                    token: token,
                    isAdmin: isAdmin,
                    isModemAccessAllowed: isModemAccessAllowed
                };
                // console.log(isAdmin);
                // const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
                localStorage.setItem('token', token);
                // localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('user', username);
                localStorage.setItem('isAdmin', isAdmin);
                localStorage.setItem('isModemAccessAllowed', isModemAccessAllowed);
                dispatch(authSuccess(userData));
                // dispatch(checkAuthTimeout(3600));
            })
            .catch(err => {
                dispatch(authFail(err))
            })
    }
};

export const authSignup = (username, email, password1, password2) => {
    return dispatch => {
        dispatch(authStart());
        axios.post(API.signup, {
            username: username,
            email: email,
            password1: password1,
            password2: password2
        })
            .then(res => {
                const token = res.data.key;
                // const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
                localStorage.setItem('token', token);
                // localStorage.setItem('expirationDate', expirationDate.toDateString());
                localStorage.setItem('user', username);
                dispatch(authSuccess(token));
                // dispatch(checkAuthTimeout(3600));
            })
            .catch(err => {
                dispatch(authFail(err))
            })
    }
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (token === undefined) {
            dispatch(logout());
        } else {
            // const expirationDate = new Date(localStorage.getItem('expirationDate'));
            // if (expirationDate <= new Date()) {
            //     dispatch(logout());
            // } else {
            const userData = {
                token:token,
                isAdmin:localStorage.getItem('isAdmin'),
                isModemAccessAllowed:localStorage.getItem('isModemAccessAllowed')
            };
            dispatch(authSuccess(userData));
                // dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
            // }
        }
    }
};