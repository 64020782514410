import React from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import {List} from "antd";
import {withRouter} from "react-router-dom";
import throttle from 'lodash.throttle';
import 'mapbox-gl/dist/mapbox-gl.css';

import { Link } from "react-router-dom";

import {WaveLoading} from "react-loadingg";
import {isMobile} from 'react-device-detect'
import EGS_Logo from "../assets/EGS_Logo.png";
import MetaTags from "react-meta-tags";
import {API, token} from '../config'

const getDeviceConfig = (width) => {
    if (isMobile){
        return width-30
    }
    //size xs
    else if(width < 576) {
        return (width-47.0).toFixed(0)
    }
    //size sm
    else if(width >= 576 && width < 768 ) {
        return (width-47.0).toFixed(0)
    }
    // size md
    else if(width >= 768 && width < 992) {
        return (width-47.0).toFixed(0)
    }
    //size lg
    else if (width >= 992 && width < 1200) {
        return (((width-47.0)/2.0)-7.0).toFixed(0)
    }
    //size xl
    else if (width >= 1200 && width < 1600) {
        return (((width-47.0)/3.0)-7.0).toFixed(0)
    }
    //size xxl
    else if(width >= 1600 ) {
        return (((width-47.0)/4.0)-10.5).toFixed(0)
    }
}

class DeploymentListView extends React.Component {

    state={
        deployments: [],
        viewport: {
            width: "100%",
            height: "37vh",
            latitude: 22.338551,
            longitude: 114.101757,
            zoom: 8.5
        },
        width: 0,
        height: 0,
        loading: false
    };

    updateDimensions = () => {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight
        });
    };

    componentDidMount() {
        window.addEventListener('resize', throttle(this.updateDimensions, 200));
        try {
            this.setState({ loading: true }, () => {
                axios.defaults.headers = {
                    'Content-Type': "application/json",
                    Authorization: `Token ${localStorage.getItem('token')}`
                };
                axios.get(API.deployments)
                axios.get(API.deployments)
                    .then(res => {
                        this.setState({
                            loading: false,
                            deployments: res.data
                        });
                    });
            });
        } catch (err) {
            throw err;
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render(){

        return (
            <div>
                <MetaTags>
                    <meta name="description" content="Tide gauge monitoring system" />
                    <meta property="og:title" content="EGS Sensor Monitor" />
                    <meta property="og:image" content={EGS_Logo} />
                </MetaTags>
                {this.state.loading ?
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <WaveLoading />
                    </div>
                    :
                    <div>
                        {(this.state.deployments.length !== 0) ? (
                            <List
                                grid={{
                                    gutter: 14,
                                    xs: 1,
                                    sm: 1,
                                    md: 1,
                                    lg: 2,
                                    xl: 3,
                                    xxl: 4,
                                }}
                                dataSource={this.state.deployments}
                                renderItem={item => (
                                    <List.Item key={item.id}>
                                        <div style={{color: 'black'}}>
                                            <Link to={`/deployments/${item.id}/`} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                                                <div style={{overflow: 'hidden', borderRadius: '5px 5px 5px 5px'}}>
                                                    <div
                                                        style={{
                                                            textAlign: 'center',
                                                            minHeight: '56px',
                                                            height: 'auto',
                                                            padding: '16px 24px 16px 24px',
                                                            backgroundColor: item.deployment_device.ownership_state ? '#9ebfeb' : '#30a06d',
                                                            fontSize: '16px',
                                                            fontFamily: 'Segoe UI Symbol',
                                                            fontWeight: 'bold'
                                                        }}
                                                    >
                                                        {item.deployment_device.ownership_state ?
                                                            <>
                                                                <img src={EGS_Logo} style={{height: '20px'}} alt="Logo"/> {item.deployment_device.title_amended}
                                                            </>
                                                            :
                                                            <>
                                                                {item.deployment_device.title_amended}
                                                            </>
                                                        }
                                                    </div>
                                                    <img
                                                        alt='static map of Tide Gauge Location'
                                                        src={`${API.mapBoxStatic}pin-s-lighthouse+ff5500(${parseFloat(item.longitude)},${parseFloat(item.latitude)})/${parseFloat(item.longitude)},${parseFloat(item.latitude)},8.5,0.00,0.00/${getDeviceConfig(window.innerWidth)}x200?access_token=${token}`}
                                                    />
                                                </div>
                                            </Link>
                                        </div>
                                    </List.Item>
                                )}
                            />
                        ) : null}
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.token
    }
};

export default withRouter(connect(mapStateToProps)(DeploymentListView));