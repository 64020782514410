import React from 'react'
import { Layout, Menu, Switch, Row, Col} from 'antd';
import {Link, withRouter} from 'react-router-dom'
import * as actions from "../store/actions/auth";
import {connect} from 'react-redux'
import { Popconfirm, message } from 'antd'

import {FiSettings} from 'react-icons/fi';
import EGS_Logo from '../assets/EGS_Logo.png'
import MetaTags from "react-meta-tags";
import {isMobile} from 'react-device-detect'
import {Nav, Navbar} from 'react-bootstrap'

const {Header, Content, Footer} = Layout;



document.title = 'EGS TIDE';


class CustomLayout extends React.Component {
    state ={
        timezone: true,
        loading: false
    };

    handleTimeZoneToggle = (state) => {
        this.props.onTimeZoneChange(!state);
        // this.setState({ loading: true }, () => {
        setTimeout(() => {
            this.setState({
                loading: false,
                timezone: !state
            });
        }, 1000)
        // });
    };

    changingTimeZone= (e) => {
        this.handleTimeZoneToggle(this.state.timezone)
        message.success('Timezone Updated');
    }

    notChangingTimeZone(e) {
      message.error('Timezone NOT Updated');
    }


    render() {
        // console.log(this.props)
        return (
            <div className="h-100">
                {isMobile ?
                    <div style={{height: "calc(100vh - 59px)"}}>
                        <div id='childrenDiv' className="container-fluid h-100 py-3" style={{background: '#e8e8e8', overflow: "auto"}}>
                            {this.props.children}
                            {this.props.isAuthenticated}
                        </div>
                        <div style={{height:"59px"}}>

                        </div>
                        <Navbar bg="dark" variant="dark" expand="lg" fixed="bottom" style={{background: '#001529', textColor: 'white'}}>
                            <Navbar.Brand href="#home"><img src={EGS_Logo} style={{height: '30px'}} alt="Logo"/>   EGS TIDE</Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="mr-auto">
                                    <Nav.Link href="/map/">Map</Nav.Link>
                                    <Nav.Link href="/dashboard/">Dashboard</Nav.Link>
                                    <Nav.Link href="/deployments/">Deployment</Nav.Link>
                                    <Nav.Link href="/compare/">Compare</Nav.Link>
                                    {this.props.isModemAccessAllowed &&
                                        <Nav.Link href="/access/">Remote Access</Nav.Link>
                                    }
                                    {this.props.isAdmin &&
                                        <Nav.Link href="/maintenance/">Maintenance</Nav.Link>
                                    }
                                    {this.props.isAuthenticated ?
                                        (
                                            <Nav.Link href="/logout/" onSelect={this.props.logout}>Logout</Nav.Link>
                                        ) : (
                                            <Nav.Link href="/login/">login</Nav.Link>
                                        )
                                    }
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>

                    </div>
                    :
                    <Layout className="layout h-100">
                        <MetaTags>
                            <meta name="description" content="Tide guage monitoring sysytem" />
                            <meta property="og:title" content="EGS TIDE" />
                            <meta property="og:image" content={EGS_Logo} />
                            <meta http-equiv="pragma" content="no-cache" />
                            <meta http-equiv="expires" content="0" />
                            <meta http-equiv="cache-control" content="no-cache" />
                        </MetaTags>
                        <Header>
                            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']} style={{lineHeight: '64px'}}>
                                {this.props.isAuthenticated ?
                                    (
                                        <Menu.Item key="2" onClick={this.props.logout} style={{float: 'right'}}>
                                            <Link to="/login"> Logout </Link>
                                        </Menu.Item>
                                    ) : (
                                        <Menu.Item key="2" style={{float: 'right'}}>
                                            <Link to="/login"> Login </Link>
                                        </Menu.Item>
                                    )
                                }
                                {this.props.isAdmin &&
                                    <Menu.Item key="6" style={{float: 'right'}} >
                                        <Link to="/maintenance/">
                                            <span>
                                                <FiSettings style={{fontSize: "20px"}}/>
                                            </span>
                                        </Link>
                                    </Menu.Item>
                                }
                                <Menu.Item key="home" to="/map/">
                                    <Link to="/map/"><img src={EGS_Logo} style={{height: '30px'}} alt="Logo"/></Link>
                                </Menu.Item>
                                <Menu.Item key="3">
                                    <Link to="/deployments/"><span> Deployment </span></Link>
                                </Menu.Item>
                                <Menu.Item key="4">
                                    <Link to="/dashboard/"><span>Dashboard</span></Link>
                                </Menu.Item>
                                <Menu.Item key="5">
                                    <Link to="/compare/">
                                        <span>Compare</span>
                                    </Link>
                                </Menu.Item>
                                {this.props.isModemAccessAllowed &&
                                    <Menu.Item key="7">
                                        <Link to="/access/">
                                            <span>Access</span>
                                        </Link>
                                    </Menu.Item>
                                }
                            </Menu>
                        </Header>
                        <Content style={{padding: '0px 0px'}}>
                            <div id='childrenDiv' className="container-fluid h-100 py-3" style={{background: '#e8e8e8', overflow: "auto"}}>
                                {this.props.children}
                                {this.props.isAuthenticated}
                            </div>
                        </Content>
                        <Footer style={{textAlign: 'center'}}>
                            EGS Sensor Monitor ©2023 Created by EGS (ASIA) LIMITED
                            <div style={{float: 'right'}}>
                                <Row gutter={2}>
                                    <Col span={19}>
                                        Time Zone :
                                    </Col>
                                    <Col span={3}>
                                        <Popconfirm
                                            title="Are you sure you want to change Timezone?"
                                            onConfirm={this.changingTimeZone}
                                            onCancel={this.notChangingTimeZone}
                                            okText="Change settings"
                                            cancelText="Keep Settings"
                                            >
                                            <Switch
                                                checked={this.state.timezone}
                                                checkedChildren="local"
                                                unCheckedChildren="origin"
                                            />
                                        </Popconfirm>
                                    </Col>
                                </Row>
                            </div>
                        </Footer>
                    </Layout>
                }
            </div>
        );
    }

}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actions.logout()),
    }
};

export default withRouter(connect(null, mapDispatchToProps)(CustomLayout));
