import React from 'react'

import { Form } from '@ant-design/compatible';
import { Spin, Input, Button } from 'antd';
import {connect} from 'react-redux';
import * as actions from '../store/actions/auth';
import {withRouter} from 'react-router-dom'
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import EGS_Logo from "../assets/EGS_Logo.png";
import Background from '../assets/BackgroundLogin.jpg';
//import Recaptcha from 'react-recaptcha'
import {Icon} from "semantic-ui-react";
import {isMobile} from "react-device-detect";
import MetaTags from "react-meta-tags";


class NormalLoginForm extends React.Component {
    _clicked = false;
    _mounted = false;
    state = {
        //isVerified: false,
        //recaptchaLoaded: false
        isVerified: true,
        recaptchaLoaded: true
	};

    reCaptchaLoaded () {
        this.setState({
            recaptchaLoaded: true
        })
    }
    reCaptchaVerified () {
        this.setState({
            isVerified: true
        })
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!this.state.isVerified){
                alert('Please verify that you are not a robot')
            }
            else if (!err) {
                this.props.onAuth(values.username, values.password);
                this._clicked = true;

            } else {
                console.log(err)
            }
        });
    };

    getCurrentCoordinates() {
        try {
            navigator.geolocation.getCurrentPosition((position) => {
                // console.log(position)
                if (position !== undefined) {
                    localStorage.setItem('latitude', position.coords.latitude);
                    localStorage.setItem('longitude', position.coords.longitude);
                }
            })
        }
        catch(err){
            console.log(err)
            localStorage.setItem('latitude', "22.396427")
            localStorage.setItem('longitude', "114.109497")
        }
    }

    componentDidMount() {
        localStorage.setItem('latitude', "22.396427")
        localStorage.setItem('longitude', "114.109497")
        this.getCurrentCoordinates()
        this._mounted=true;
        //const script = document.createElement("script");

        //script.src = "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit";
        //script.async = false;

        //document.body.appendChild(script);
    }

    componentWillUnmount() {
        this._mounted=false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isLoggedIn !== this.props.isLoggedIn && this._mounted) {
            if (this.props.isLoggedIn) {
                // console.log(this.props.isAdmin);
                this.getCurrentCoordinates()
                localStorage.setItem('timeZone', "true");
                this.getCurrentCoordinates()
                if(this._clicked){
                    if (this.props.isAdmin && isMobile){
                        this.props.history.push('/maintenance/');
                    }
                    else{
                        this.props.history.push('/map/');
                    }
                }else{
                    if (this.props.isAdmin && isMobile){
                        this.props.history.push('/maintenance/');
                    }
                    else{
                        this.props.history.push('/map/');
                    }
                }
            }
        }
    }

    render() {
        const classes = makeStyles(theme => ({
            paper: {
                marginTop: theme.spacing(8),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            },
            avatar: {
                margin: theme.spacing(1),
                backgroundColor: theme.palette.secondary.main,
            },
            form: {
                width: '100%', // Fix IE 11 issue.
                marginTop: theme.spacing(1),
            },
            submit: {
                margin: theme.spacing(3, 0, 2),
            },
        }));

        const {getFieldDecorator} = this.props.form;
        return (
            <div className="h-100" style={{textAlign: 'center', backgroundImage: `url(${Background})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                <MetaTags>
                    <meta name="description" content="Tide guage monitoring sysytem" />
                    <meta property="og:title" content="EGS Sensor Monitor" />
                    <meta property="og:image" content={EGS_Logo} />
                </MetaTags>
                {this.props.loading ?
                    <Spin />
                    :
                    <div className="row h-100 mx-0">
                        <div className="col-sm-12 center d-flex align-items-center">
                            <Container component="main" maxWidth="xs" style={{backgroundColor : 'lightblue', padding : '10px 10px', borderRadius: '10px'}}>
                                <CssBaseline/>
                                <div className={classes.paper}>
                                    <img src={EGS_Logo} style={{height: '50px'}} alt="Logo"/>
                                    <Typography component="h1" variant="h5" style={{padding: '5px'}}>
                                        Sensor Monitor
                                    </Typography>
                                    <Form onSubmit={this.handleSubmit} className={classes.form}>
                                        <div style={{padding: '10px'}}>
                                            <Form.Item>
                                                {getFieldDecorator('username', {
                                                    rules: [{required: true, message: 'Please input your username!'}],
                                                })(
                                                    <Input
                                                        prefix={<Icon name='user' style={{color: 'rgba(0,0,0,.25)'}} />}
                                                        placeholder="Username"
                                                    />,
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div style={{padding: '10px'}}>
                                            <Form.Item>
                                                {getFieldDecorator('password', {
                                                    rules: [{required: true, message: 'Please input your Password!'}],
                                                })(
                                                    <Input
                                                        prefix={<Icon name='lock' style={{color: 'rgba(0,0,0,.25)'}} />}
                                                        type="password"
                                                        placeholder="Password"
                                                    />,
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div>
                                            {(this.props.error) &&
                                                <p style={{color:'red'}}> Please check the Username and Password again</p>
                                            }
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                padding: '0px 10px 10px 10px '
                                            }}
                                        >

                                        </div>
                                        <Button
                                            htmlType="submit"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                        >
                                            Sign In
                                        </Button>

                                        {/*</Form.Item>*/}
                                        {/*<Grid container>*/}
                                        {/*    <Grid item xs>*/}
                                        {/*        <Link href="#" variant="body2">*/}
                                        {/*            Forgot password?*/}
                                        {/*        </Link>*/}
                                        {/*    </Grid>*/}
                                        {/*    <Grid item>*/}
                                        {/*        <Link href="#" variant="body2">*/}
                                        {/*            <NavLink style={{marginRight: '10px'}}*/}
                                        {/*                     to='/signup/'> {"Don't have an account? Sign Up"}</NavLink>*/}
                                        {/*        </Link>*/}
                                        {/*    </Grid>*/}
                                        {/*</Grid>*/}
                                    </Form>
                                </div>
                            </Container>
                        </div>
                    </div>
                }
            </div>
        );

    }
}


const WrappedNormalLoginForm = Form.create({name: 'normal_login'})(NormalLoginForm);

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error,
        isLoggedIn: state.loggedIn
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (username, password) => dispatch(actions.authLogin(username, password))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WrappedNormalLoginForm));