import React from 'react'

import {Col, Row} from 'antd';
import {MdRouter } from 'react-icons/md';
import {FaNetworkWired, FaMicrochip } from 'react-icons/fa';
import {TiFlowSwitch } from 'react-icons/ti';
import {IoMdLocate} from 'react-icons/io';
import {GiLowTide, GiHighTide}  from 'react-icons/gi'
import { AiFillPhone } from 'react-icons/ai'
import {GrShift} from 'react-icons/gr'

const inChargeDetails = (data) => {
    let userLoggedIn = localStorage.getItem('user');
    for (const user of data) {
        if (user.username.toLowerCase() === userLoggedIn.toLowerCase()){
            return user
        }
    }
    // return null;
    return null;
};


const DeploymentData = (props) => {
    return (
        <div id={props.data.id}>
            <Row gutter={16}>
                <Col span={3}>
                    <IoMdLocate style={{fontSize: '20px', color: '#2e2e28'}}/>
                </Col>
                <Col span={21}>
                    {parseFloat(props.data.longitude).toFixed(5)}, {parseFloat(props.data.latitude).toFixed(5)}
                </Col>
            </Row>
            {(inChargeDetails(props.data.in_charge).groups[0].name !== 'Client') ? (
            // {this.props.isAdmin ? (
                <Row gutter={16}>
                    <Col span={3}>
                        {/*<Icon  style={{fontSize: '20px', color: '#2e2e28'}} name='phone volume'/>*/}
                        <AiFillPhone style={{fontSize: '20px', color: '#2e2e28'}}/>
                    </Col>
                    <Col span={21}>
                        {props.data.deployment_device.sim_1.__str__}
                    </Col>
                </Row>
            ) : null}

            {(props.data.deployment_device.sim_2 !== null) && (inChargeDetails(props.data.in_charge).groups[0].name !== 'Client') ?
            {/*{(props.data.deployment_device.sim_2 !== null) && this.props.isAdmin ?*/}
                (
                <Row gutter={16}>
                    <Col span={3}>
                        {/*<Icon  style={{fontSize: '20px', color: '#2e2e28'}} name='phone volume'/>*/}
                        <AiFillPhone style={{fontSize: '20px', color: '#2e2e28'}}/>
                    </Col>
                    <Col span={21}>
                        {props.data.deployment_device.sim_2.__str__}
                    </Col>
                </Row>
                ) : null
            }
            <Row gutter={16}>
                <Col span={3}>
                    <MdRouter style={{fontSize: '20px', color: '#2e2e28'}}/>
                </Col>
                <Col span={21}>
                    {props.data.deployment_device.modem.modem_name} - {props.data.deployment_device.modem.modem_serial}
                </Col>
            </Row>
            {(props.data.deployment_device.sim_1.fixed_ip !== null) && (inChargeDetails(props.data.in_charge).groups[0].name !== 'Client') ? (
                <Row gutter={16}>
                    <Col span={3}>
                        <FaNetworkWired  style={{fontSize: '20px', color: '#2e2e28'}}/>
                    </Col>
                    <Col span={21}>
                        {props.data.deployment_device.sim_1.fixed_ip}
                    </Col>
                </Row>
                ) : null
            }
            {(props.data.deployment_device.switch !== null) ? (
                <Row gutter={16}>
                    <Col span={3}>
                        <TiFlowSwitch style={{fontSize: '20px', color: '#2e2e28'}}/>
                    </Col>
                    <Col span={21}>
                        {props.data.deployment_device.switch.switch_name} - {props.data.deployment_device.switch.switch_serial}
                    </Col>
                </Row>
                ) : null
            }
            {props.data.deployment_device.sensor.map((sensor) =>
                <Row gutter={16} key={props.data.id}>
                    <Col span={3}>
                        <FaMicrochip style={{fontSize: '20px', color: '#2e2e28'}}/>
                    </Col>
                    <Col span={21}>
                        {sensor.sensor.sensor_name}
                    </Col>
                </Row>
            )}
            {props.data.low_trig !== null ? (
                <Row gutter={16}>
                    <Col span={3}>
                        <GiLowTide style={{fontSize: '20px', color: '#2e2e28'}}/>
                    </Col>
                    <Col span={21}>
                        {props.data.low_trig}m - (Low Tide Trigger)
                    </Col>
                </Row>
            ) : null}
            {props.data.high_trig !== null ? (
                <Row gutter={16}>
                    <Col span={3}>
                        <GiHighTide style={{fontSize: '20px', color: '#2e2e28'}}/>
                    </Col>
                    <Col span={21}>
                        {props.data.high_trig}m - (High Tide Trigger)
                    </Col>
                </Row>
            ):null}
            {props.data.deployment_device.offset !== 0.0 &&
                <Row gutter={16}>
                    <Col span={3}>
                        <GrShift style={{fontSize: '20px', color: '#2e2e28'}}/>
                    </Col>
                    <Col span={21}>
                        {props.data.deployment_device.offset}m (offset applied)
                    </Col>
                </Row>
            }
        </div>
    );
};

export default DeploymentData