import {Switch} from 'react-router-dom';
import React from 'react';
// import Signup from './containers/Signup'
import DeploymentList from "./containers/DeploymentListView";
import DeploymentDetail from "./containers/DeploymentDetailView";
import Map from "./containers/Map";
import Dashboard from "./containers/Dashboard";
import Compare from "./containers/Compare";
import Maintenance from "./containers/Maintenance"
// import Modem from "./containers/Modem"
import Access from "./containers/Access"
import Login from "./containers/Login";
import RouteWrapper from "./routing/Route";
import NoMatch from "./NoMatch"


const BaseRouter = (props) => (

    <div className="h-100">
        <Switch>
            <RouteWrapper {...props} isLoggedIn={props.isLoggedIn}  isAdmin={props.isAdmin} path='/map' component={Map}/>
            <RouteWrapper {...props} isLoggedIn={props.isLoggedIn} isAdmin={props.isAdmin} path='/dashboard' component={Dashboard}/>
            <RouteWrapper {...props} isLoggedIn={props.isLoggedIn} isAdmin={props.isAdmin} exact path='/deployments'
                          component={DeploymentList}/>
            <RouteWrapper {...props} isLoggedIn={props.isLoggedIn} isAdmin={props.isAdmin} path='/deployments/:deploymentID'
                          component={DeploymentDetail}/>
            {/*<Route exact path='/login/' component={Login} />*/}
            {/*<RouteWrapper {...props} isLoggedIn={props.isLoggedIn} isAdmin={props.isAdmin} exact path='/signup/' component={Signup}/>*/}
            <RouteWrapper {...props} isLoggedIn={props.isLoggedIn}  isAdmin={props.isAdmin} path='/compare' component={Compare}/>
            <RouteWrapper {...props} isLoggedIn={props.isLoggedIn} isAdmin={props.isAdmin} path='/maintenance' component={Maintenance}/>
            {/*<RouteWrapper {...props} isLoggedIn={props.isLoggedIn} isAdmin={props.isAdmin} path='/modem' component={Modem}/>*/}
            <RouteWrapper {...props} isLoggedIn={props.isLoggedIn} isAdmin={props.isAdmin} path='/access' component={Access}/>
            {/*loggedIn?*/}
            <RouteWrapper {...props} isLoggedIn={true} path="/NoMatch" isAdmin={props.isAdmin}  component={NoMatch}/>

            <RouteWrapper isLoggedIn={false} isAdmin={props.isAdmin}  component={Login}/>

        </Switch>
    </div>
);


export default BaseRouter