import React from 'react';
import {connect} from "react-redux";
import axios from "axios";
import {Line} from "react-chartjs-2";
import 'chartjs-plugin-annotation';
import {API} from "../config";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import {trackPromise} from 'react-promise-tracker';
import {Col, Row, Button, Switch} from 'antd';
import Draggable from 'react-draggable'
import EGS_Logo from "../assets/EGS_Logo.png";
import MetaTags from "react-meta-tags";
import moment from "moment-timezone";
import {isMobile} from "react-device-detect";
import {WaveLoading} from "react-loadingg";
import 'chartjs-plugin-zoom';
import {colourList} from '../components/Colours'
import 'antd/dist/antd.css';
import Grid from "@material-ui/core/Grid";

class Compare extends React.Component {

	chartReference = React.createRef();
	graph = React.createRef();

	state = {
		deployments: [],
		switchState: {},
		colourPalette: [],
        timeZoneBrowser: Intl.DateTimeFormat().resolvedOptions().timeZone,
		days: '1day',
		tideData: [],
		initialDataRetrieval : false,
		showTideData : [],
		processedTideData: [],
		gotProcessedData: false,
		updateChart: false,
		needToReload: false,
		noData: [],
		latestData: [],
		hideLatest: true
	};

	intervalID = [];
	loadingStage = `Choose Tide Gauge to Compare`


	handleClick =(id) => {
		const oldState = this.state.switchState[id];
		let new_showTideData = this.state.showTideData;
		if (oldState){
			new_showTideData[id].data = []
		}
		else{
			new_showTideData[id].data = this.state.processedTideData[id].data
		}
		this.setState((prevState) => {
			return {
				switchState: { ...prevState.switchState, [id]: !prevState.switchState[id] },
				showTideData: new_showTideData
			}
		},() => this.scrollToBottomGraph());
	};

	componentDidMount() {
		try {
			if (this.props.token !== null) {
				this.getData();
			}
		} catch (err) {
			throw err;
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.token !== prevProps.token) {

			this.getData()

		}else if (prevState.deployments !== this.state.deployments && !this.state.initialDataRetrieval){

			this.getSensorData();

		}

		else if (prevState.days !== this.state.days){

			this.setState({
				initialDataRetrieval : false,
				gotProcessedData: false,
			}, ()=>{this.getSensorData()})

			this.setState({
				updateChart: true
			})

		}
		else if (this.state.tideData.length === this.state.deployments.length && this.state.initialDataRetrieval && !this.state.gotProcessedData){
			// console.log("process data");
			this.loadingStage = `Choose Tide Gauge to Compare`;
			let sensorDataProcessed = [];
			let showTide = this.state.showTideData;
			const ifFirstLoop = this.state.showTideData.length === 0 ;
			for (const [index, sensor] of this.state.tideData.entries()){
				this.generateChartData(sensor, index).then((response)=>(
					sensorDataProcessed[index] = response
				));
				if (ifFirstLoop){
					this.generateChartData([], index).then((response)=>(
						showTide[index] = response
					));
				}
				else if (this.state.switchState[index]) {
					// console.log(this.state.deployments[index].deployment_device.title_amended)
					this.generateChartData(sensor, index).then((response) => (
						showTide[index] = response
					));
				}
			}
			this.setState({
				showTideData: showTide,
				processedTideData: sensorDataProcessed,
				gotProcessedData: true
			})
			this.setState({
				needToReload: true
			})
		}
		else if (this.state.needToReload){
			this.setState({
				needToReload: false
			}, ()=>{setTimeout(()=>{this.updateAllCharts()}, 1000)})
		}
		else if (this.state.tideData.length === this.state.deployments.length && !this.state.initialDataRetrieval && this.state.gotProcessedData){
			// console.log('%c Oh my heavens! ', 'background: #222; color: #bada55');
			this.setState({
				updateChart: true,
				initialDataRetrieval: true,
				gotProcessedData: false
			})
		}
		if ((this.state.tideData.length < this.state.deployments.length && !this.state.gotProcessedData)){
			this.loadingStage = `loading... ${this.state.tideData.length+1} of ${this.state.deployments.length}`
		}

	}



	componentWillUnmount() {
		for (let i = 0; i < this.intervalID.length; i++) {
			clearTimeout(this.intervalID[i])
		}
		this.intervalID = []
	}

	handleLatestDataShow = () => {
		this.setState({
			hideLatest: !this.state.hideLatest
		});
    };


	getData() {
		try {
			axios.defaults.headers = {
				'Content-Type': "application/json",
				Authorization: `Token ${this.props.token}`
			};
			axios.get(API.deployments)
				.then(res => {
					const number = res.data.length;
					let state = {};
					let reloadData = [];
					res.data.map((val, index) => {
						state[index] = false;
						reloadData[index] = false;
						return null
					});
					this.setState({
						switchState: state,
						deployments: res.data,
						colourPalette: colourList.slice(0, number),
						initialDataRetrieval : false,
						reloadData: reloadData
					})
				});
		} catch (err) {
			throw err;
		}

	};
	getSensorData() {
		if ((this.state.deployments.length !== 0)) {
			this.state.deployments.map((sensor, index) => (
				this.getSensorDataIndividual(sensor, index)
			));
			setTimeout(()=>{
				this.setState({
					initialDataRetrieval: true
				})
			}, 1000)

		}
	}
	getSensorDataIndividual = (sensor, index) => {
		const that = this;
			const getSensorData = axios.get(`${API.sensorData}${sensor.deployment_device.token}/?date=${this.state.days}`)
			trackPromise(
				Promise.all([getSensorData]).then(function (res) {
					if (res[0].data !== undefined ){
						const latestData = res[0].data;

						// let sensorData = latestData;
						let latestDataList = that.state.latestData;


						const latestDataPoint = latestData[latestData.length -1];

						if (latestDataPoint !== undefined) {
							const latestDataPoint_date = latestDataPoint.date;

							latestDataList[index] = `${that.convertTimezone(latestDataPoint.date, sensor.timezone)} - ${latestDataPoint.tide}m`;

							const delay = that.getDelayFromDate(latestDataPoint_date);

							// console.log(`got data from ${sensor.deployment_device.title_amended} - ${latestDataPoint_date}`);

							clearTimeout(that.intervalID[index])

							// if (sensor.deployment_device.ownership_state) {
							// console.log(delay, sensor.deployment_device.deployment_title)
							if (delay > 0 && delay < 360000){
								that.intervalID[index] = setTimeout(() => {that.getSensorDataIndividual(sensor, index)}, delay);
							}
							else{
								that.intervalID[index] = setTimeout(() => {that.getSensorDataIndividual(sensor, index)}, 101000);
							}
							// } else if(!sensor.deployment_device.ownership_state){
							// 	console.log(delay+310000, sensor.deployment_device.deployment_title)
							// 	const new_delay = delay + 310000;
							// 	if (new_delay > 0 && new_delay < 610000){
							// 		that.intervalID[index] = setTimeout(() => {that.getSensorDataIndividual(sensor, index)}, new_delay);
							// 	}
							// 	else{
							// 		that.intervalID[index] = setTimeout(() => {that.getSensorDataIndividual(sensor, index)}, 601000);
							// 	}
							//
							// }
							// else {
							//
							// 	that.intervalID[index] = setTimeout(() => {that.getSensorDataIndividual(sensor, index)}, 301000);
							//
							// }
						} else {

							latestDataList[index] = `Not Available`;

							that.intervalID[index] = setTimeout(() => {that.getSensorDataIndividual(sensor, index)}, 301000);
						}

						let dataPoints = [];
						const timezoneString = that.state.deployments[index].timezone;
						for (const [index, dataPoint] of latestData.entries()){
							dataPoints[index] = {
								t: that.convertTimezone(dataPoint.date, timezoneString),
								y: parseFloat(dataPoint.tide).toFixed(3)
							}
						}

						let tideData_toBeUpdated = that.state.tideData;
						tideData_toBeUpdated[index] = dataPoints;

						let noData_toBeUpdated = that.state.noData;
						noData_toBeUpdated[index]= false;

						that.setState({
							tideData: tideData_toBeUpdated,
							noData: noData_toBeUpdated,
							initialDataRetrieval : false,
							latestData: latestDataList
						})

					} else {
						let tideData_toBeUpdated = that.state.tideData;
						tideData_toBeUpdated[index] = [];

						let noData_toBeUpdated = that.state.noData;
						noData_toBeUpdated[index]= true;

						that.setState({
							tideData: tideData_toBeUpdated,
							noData: noData_toBeUpdated,
							initialDataRetrieval : false
						})
					}

			}));
		// })
	};


	 getDelayFromDate = (dateValue) => {
        const lastTime_sensor = new Date(moment.utc(dateValue, 'YYYY-MM-DD HH:mm:ss'));
        const nowTime = new Date(moment.utc());
        const delay_sensor_egs = (new Date(lastTime_sensor).getTime() - nowTime.getTime()) + 301000;
        // console.log(delay_sensor_egs);
        return delay_sensor_egs;
    };

	convertTimezone = (time, originTimeZone) => {
        const browserTimeZone = this.state.timeZoneBrowser
        const utcTime = moment.utc(time, 'YYYY-MM-DD HH:mm:ss')
        const originTime = utcTime.tz(originTimeZone).format('YYYY-MM-DD HH:mm:ss')
        const localTime = utcTime.tz(browserTimeZone).format('YYYY-MM-DD HH:mm:ss')
        if (this.props.timeZone !== true) {

            return (originTime)
        }
        else{
            return (localTime)
        }
    }

	 generateChartData = async(tideDataValues_yt, index) => {
		const colour = `#${this.state.colourPalette[index]}`;
		return  {
			label: `${this.state.deployments[index].deployment_device.title_amended}`,
			pointBackgroundColor: colour,
			backgroundColor: 'rgb(0,0,0,0)',
			borderJoinStyle: 'miter',
			lineTension: 0,
			borderColor: colour,
			borderCapStyle: 'butt',
			borderDash: [],
			borderDashOffset: 0.0,
			pointBorderColor: colour,
			pointBorderWidth: 1,
			pointHoverRadius: 5,
			pointHoverBorderColor: colour,
			pointHoverBorderWidth: 2,
			pointRadius: 2,
			pointHitRadius: 10,
			spanGaps: false,
			legend: {
				display: false
			},
			cubicInterpolationMode: 'monotone',
			data: await tideDataValues_yt,
		};
	};

	updateAllCharts() {
		if (this.chartReference.chartInstance !== null){
			if(this.chartReference){
				if(this.chartReference.chartInstance.config){
					this.chartReference.chartInstance.config.data = {
						datasets: this.state.showTideData
					};
					this.chartReference.chartInstance.update();
				}
			}
		}

	}

	getGraphHeight = () => {
		if (isMobile){
			return (window.innerHeight - 182)
		}
		return (window.innerHeight - 268)
	};

	onStart = () => {
		let prev_state = this.state.activeDrags
		this.setState({activeDrags: ++prev_state});
	};

	onStop = () => {
		let prev_state = this.state.activeDrags
		this.setState({activeDrags: --prev_state});
	};

	handleClickChangeRange = (val)=> {
        localStorage.setItem('date', val)
		this.setState({
            days: val
        });
    };

	scrollToBottomGraph = () => {
        const scroll = this.graph.current.scrollHeight - this.graph.current.clientHeight;
        this.graph.current.scrollTo(0, scroll);
    };

	render() {
		const options = {
			animation: false,
			maintainAspectRatio: false,
			zoom: {
                enabled: true,
                mode: 'x',
            },
            pan: {
                enabled: true,
                mode: 'x',
            },
			tooltips: {
				mode: 'nearest'
			},
			title: {
				display: !isMobile,
				text: 'Tide Gauge Comparison Chart',
				fontStyle: 'bold',
				fontSize: isMobile ? 15 : 20 ,
				fontColor: '#ffffff'
			},
			legend: false,
			scales: {
				xAxes: [{
					gridLines: {
						display: true ,
						color: "rgba(255,255,255,0.1)"
					},
					type: 'time',
                    time: {
                        displayFormats: {
                            hour: 'hA'
                        },
                    },
					ticks: {
						fontColor:"rgba(255,255,255,1.0)",
						maxTicksLimit: 12,
					}
				}],
				yAxes: [{
					gridLines: {
						display: true ,
						color: "rgba(255,255,255,0.1)"
					},
					ticks: {
						fontColor:"rgba(255,255,255,1.0)",
						maxTicksLimit: 6,
						callback: function (value, index, values) {
							return value + "m";
						}
					}
				}]
			}
		}
		const dragHandlers = {onStart: this.onStart, onStop: this.onStop};

		return (
			<div>
				<MetaTags>
                    <meta name="description" content="Tide gauge monitoring system" />
                    <meta property="og:title" content="EGS Sensor Monitor" />
                    <meta property="og:image" content={EGS_Logo} />
                </MetaTags>
				{(this.state.deployments.length !== 0) ? (
					<div >
						<div
							className="text-xs-center text-md-left"
							id='deploymentChip'
							style={{padding: '0px 0px 10px 0px'}}
						>
							<PopupState variant="popover" popupId="demo-popup-menu">
								{(popupState) => (
									<React.Fragment key='menuDropDown'>
										<Button
											size='large'
											type="primary"
											style={{width: isMobile && '100%'}}
											{...bindTrigger(popupState)}
											loading={!this.state.gotProcessedData}
										>
											{this.loadingStage}

										</Button>
										<Menu
											key={'menuForSelection'}
											{...bindMenu(popupState)}
										>
											{this.state.deployments.map((deployment, index)=>(
												<MenuItem
													key={deployment.deployment_device.id}
													onClick={() => {this.handleClick(index)}}
												>
													<Button
														key={`btn_${deployment.deployment_device.id}`}
														type="link"
														onClick={isMobile && popupState.close}
													>
														<Row
															style={{display: "flex"}}
														>
															<Col span={3}>
																<div
																	key={`div_${deployment.deployment_device.id}`}
																	style={{
																		backgroundColor: `#${this.state.colourPalette[index]}`,
																		height : '20px',
																		width: '20px',
																		border: '1px solid black'
																	}}
																/>
															</Col>
															<Col span={21}>
																<p
																	key={`pTag_${deployment.deployment_device.title_amended_text}`}
																	style={{
																		float: 'right',
																		padding: '0px 0px 0px 10px',
																		textDecorationLine:`${this.state.noData[index] ? 'line-through': 'null'}`,
																		fontWeight: this.state.switchState[index] ? 'bold' : 'italics',
																		fontSize : '15px',
																	}}
																>
																	{deployment.deployment_device.title_amended} {this.state.noData[index] && '- NO DATA'}

																</p>
															</Col>
														</Row>
													</Button>
												</MenuItem>
											))}
										</Menu>
									</React.Fragment>
								)}
							</PopupState>
							{!isMobile &&
								<div style={{float: 'right'}}>
									<Switch
										onClick={()=>this.handleLatestDataShow()}
										checked={this.state.hideLatest}
										checkedChildren="hide Latest"
										unCheckedChildren="show latest"
									/>
								</div>
							}
						</div>
						<div
							style={{
								height: 'auto',
								textAlign: 'left',
								alignItems: "center",
								justifyContent: "center",
								margin: '0px 0px 13px 0px'
							}}
						>
							<Grid container spacing={3} >
								{!isMobile &&
									<Grid item xs={12} lg={2} md={3}>
										<h6 style={{color: '#001529', alignItems: "center", justifyContent: "center" }}>Please choose range for data:</h6>
									</Grid>
								}
								<Grid item xs={4} sm={4} md={2}>
									<Button
										style={{ width: '100%', backgroundColor: this.state.days === '1day' ? '#ff5154' :'#1890ff', color: 'white'}}
										onClick={() => this.handleClickChangeRange('1day')}
										disabled={this.state.days === '1day'}
									> 1 Day </Button>
								</Grid>
								<Grid item xs={4} sm={4} md={2}>
									<Button
										style={{ width: '100%', backgroundColor: this.state.days === '3day' ? '#ff5154' :'#1890ff', color: 'white'}}
										onClick={() => this.handleClickChangeRange('3day')}
										disabled={this.state.days === '3day'}
									> 3 Day </Button>
								</Grid>
								<Grid item xs={4} sm={4} md={2}>
									<Button
										style={{ width: '100%', backgroundColor: this.state.days === '7day' ? '#ff5154' :'#1890ff', color: 'white'}}
										onClick={() => this.handleClickChangeRange('7day')}
										disabled={this.state.days === '7day'}
									> 7 Day </Button>
								</Grid>
							</Grid>
						</div>
						<div
							ref={this.graph}
							style = {{
								backgroundColor: '#001529',
								padding: '20px 20px',
								height: this.getGraphHeight(),
								borderRadius: '5px'
							}}
						>
							<div
							style={{
								zIndex:1,
								position: 'absolute',
								top: '210px',
								left: 'calc(100vw - 300px)',
								justifyContent: 'center',
								alignItems: 'center'
							}}>
								{!isMobile &&
									<Draggable
										{...dragHandlers}
									>
										<div
											style={{
												borderRadius: '3px 3px 3px 3px',
											}}
										>
											{this.state.deployments.map((deploy,index) =>(
												<div key={index} >
													{this.state.switchState[index] &&
														<Row
															key={`row_ledgend_${deploy.deployment_device.id}`}
															style={{
																display: "flex",
																alignItems: "center",
																justifyItems: "center",
															}}
														>
															<Col flex="none" key={`col_1_ledgend_${deploy.deployment_device.id}`}>
																<div style={{padding: '0 5px 0 0'}}>
																	<div
																		key={`color_box_ledgend_${deploy.deployment_device.id}`}
																		style={{
																			backgroundColor: `#${this.state.colourPalette[index]}`,
																			height : '15px',
																			width: '15px',
																		}}
																	/>
																</div>

															</Col>
															<Col flex="auto" key={`col_2_ledgend_${deploy.deployment_device.id}`}>
																<div
																	style={{
																		fontWeight: 'bold',
																		color: 'white',
																	}}
																>
																	{this.state.hideLatest ? (
																		<>
																			{deploy.deployment_device.title_amended}
																		</>
																	) : (
																		<>
																			<p style={{color: 'white'}}>
																				<div style={{fontWeight: 'bold'}}>{deploy.deployment_device.title_amended}</div><div style={{fontWeight: 'normal'}}>{this.state.latestData[index]}</div>
																			</p>
																		</>
																	) }

																</div>
															</Col>
														</Row>
													}
												</div>
											))}
										</div>
									</Draggable>
								}
							</div>
							<Line
								ref = {ref => this.chartReference = ref}
								height = {this.getGraphHeight()}
								data = {{datasets : this.state.showTideData}}
								options = {options}
							/>
						</div>
					</div>
				) : (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <WaveLoading />
                    </div>
                )}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		token: state.token
	}
};

export default connect(mapStateToProps)(Compare);