import React from 'react'
import {Col, Row} from 'antd';
import {ProgressBar} from "react-bootstrap";
import {isMobile} from "react-device-detect";

const batteryBar = (percentage) => {
    if (percentage === 'NaN')
        return <ProgressBar animated  variant="danger" now={0} />;
    else if (percentage > 80)
        return <ProgressBar animated  variant="success" now={percentage} />;
    else if ((percentage <= 80) && (percentage >= 40))
        return <ProgressBar animated  variant="info" now={percentage} />;
    else if (percentage < 40)
        return <ProgressBar animated  variant="danger" now={percentage} />;
}



const BatteryData = ({percentage}) => {
    return (
        <Row style={{borderRadius: '5px',backgroundColor: 'rgb(233,236,239)',  width: 'w-100', align: "middle"}}>
            <Col span={8} style={{padding: '3px 5px', borderRadius: '5px', fontSize: '11px', width: '35%', fontWeight: 'bold', justifyContent: 'center', alignItems: 'center'}}>
                {isMobile ? percentage.toFixed(0) : percentage}%
            </Col>
            <Col span={14} style={{padding: '4px 0px',  width: '60%', justifyContent: 'center', alignItems: 'center'}}>
                {batteryBar(percentage)}
            </Col>
        </Row>
    )
}

export default BatteryData