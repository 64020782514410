import React from "react";

import CustomLayout from "../containers/Layout"
import Login from "../containers/Login";
import Maintenance from "../containers/Maintenance";
import {Redirect} from "react-router";

class RouteWrapper extends React.Component {
    state = { timeZone: true };

    timeZoneChange = (timeZoneVal) => {
        this.setState({timeZone: timeZoneVal});
    };

    render() {
        const {component: Component, isAuthenticated, isAdmin} = this.props;
        const signed = isAuthenticated;
        if (Maintenance === Component) {
            if (!isAdmin && isAuthenticated) {
                return (
                    <div className="h-100">
                         <Redirect from="/maintenance" to="/" />
                    </div>
                );
            }
            // if (!isModemAccessAllowed && isAuthenticated) {
            //     return (
            //         <div className="h-100">
            //              <Redirect from="/modem" to="/" />
            //         </div>
            //     );
            // }
        }


        /**
         * Redirect user to SignIn page if he tries to access a private route
         * without authentication.
         */
        const isAuth = <CustomLayout {...this.props} onTimeZoneChange={this.timeZoneChange}><Component {...this.props} timeZone={this.state.timeZone}/></CustomLayout>;
        const isNotAuth = <Login {...this.props}/>;

        /**
         * If not included on both previous cases, redirect user to the desired route.
         */
        return (
            <div className="h-100">
                {signed ? isAuth : isNotAuth}
            </div>
        );
    }

}

export default RouteWrapper
