import React from 'react';
import axios from "axios";
import palette from "google-palette";
import {connect} from "react-redux";
import {Col, List, Row, Divider, Collapse, Skeleton } from "antd";
import BatteryData from "../components/BatteryData";
import {trackPromise} from 'react-promise-tracker';
import { Spin } from 'antd';
import {API} from "../config"
import {WaveLoading} from "react-loadingg";
import {isMobile} from "react-device-detect";
import EGS_Logo from "../assets/EGS_Logo.png";
import MetaTags from "react-meta-tags";
import moment from 'moment-timezone';
import {Chip} from '@material-ui/core'
const Panel = Collapse.Panel;


class Maintenance extends React.Component {
    state = {
        deployments: [],
        colourPalette: [],
        sensorData: [],
        visible: false,
        updated: true,
        timeZoneBrowser: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    intervalID = [];
    notification = [];
    _isMounted = false;
    _isSensorDataLoaded = false;
    maintenanceData = [];


    componentDidMount() {
        this._isMounted = true;
        if (this.props.token !== null) {
            this.getData();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.token !== prevProps.token && this._isMounted) {
            this.getData();

        // } else if ((((this.state.deployments.length !== 0) && (this.sensorValues.length === 0))||(this.props.timeZone !== prevProps.timeZone)) && this._isMounted) {
        } else if ((((this.state.deployments.length !== 0) && (this.maintenanceData.length === 0))||(this.props.timeZone !== prevProps.timeZone)) && this._isMounted) {
            this.getSensorData();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        for ( let i = 0; i < this.intervalID.length; i++){
            clearTimeout(this.intervalID[i])
        }
    }

    getData() {
        this.setState({ loading: true }, () => {
            axios.defaults.headers = {
                'Content-Type': "application/json",
                Authorization: `Token ${this.props.token}`
            };
            trackPromise(
                axios.get(API.deploymentsEGSOnly)
                .then(res => {
                    if (this._isMounted) {
                        const number = res.data.length;
                        this.setState({
                            loading: false,
                            deployments: res.data,
                            // colourPalette: palette('cb-Dark2', number)
                            colourPalette: palette('tol-rainbow', number)
                        })
                    }

                })
            );
        });
    };


    getSensorData() {
        try {
            const that = this;
            if ((this.state.deployments.length !== 0) && (this.state.deployments.length !== this.state.sensorData.length)) {
                this.state.deployments.map((sensor, index) => {

                    let MA = 'False';
                    if (sensor.deployment_device.modem.manufacturer_name === 'MTX'){
                        MA = 'True';
                    }
                    axios.defaults.headers = {
                        'Content-Type': "application/json",
                        Authorization: `Token ${this.props.token}`
                    }
                    const notificationData = axios.get(`${API.notification}${sensor.deployment_device.token}/`);
                    const maintenanceData = axios.get(`${API.maintenanceData}${sensor.deployment_device.token}/?MA=${MA}`);
                    for ( let i = 0; i < that.intervalID.length; i++){
                        clearTimeout(that.intervalID[i])
                    }
                    that.intervalID = [];

                    trackPromise(
                        Promise.all([notificationData, maintenanceData]).then(function (res) {
                            if (res[1].data !== undefined) {

                                that.maintenanceData[index] = res[1].data;

                                let delay;
                                const lastTime_battery = new Date(moment.utc(that.maintenanceData[index].battery_data_date, 'YYYY-MM-DD HH:mm:ss'));
                                const lastTime_sensor = new Date(moment.utc(that.maintenanceData[index].sensor_data_date, 'YYYY-MM-DD HH:mm:ss'));
                                const nowTime = new Date(moment.utc());
                                const delay_sensor = (new Date(lastTime_sensor).getTime() - nowTime.getTime()) + 301000;
                                const delay_battery = (new Date(lastTime_battery).getTime() - nowTime.getTime()) + 301000;
                                if (delay_sensor > delay_battery){
                                    if (isNaN(delay_battery)){
                                        delay = 30100
                                    }
                                    else{
                                        delay = delay_battery
                                    }
                                }
                                else{
                                    if (isNaN(delay_sensor)){
                                        delay = 30100
                                    }
                                    else{
                                        delay = delay_sensor
                                    }
                                }
                                if(isNaN(delay)){
                                    delay = 301000
                                }
                                if (delay >= 0) {
                                    that.intervalID.push(setTimeout(that.getSensorData.bind(that), delay));
                                }
                                // console.log(delay, that.state.deployments[index].deployment_device.title_amended)
                            } else {
                                // console.log('No sensor Data')
                                that.maintenanceData[index] = undefined;
                            }
                            if (res[0].data !== null) {
                                // console.log('notification data')
                                that.notification[index] = res[0].data
                            } else {
                                // console.log('no data')
                                that.notification[index] = undefined;
                            }
                            if (that.maintenanceData.length === that.state.deployments.length) {
                                that._isSensorDataLoaded = true;
                                that.setState({
                                    loading: false,
                                    deployments: that.state.deployments,
                                });
                            }
                        }));
                    return null
                });
            }
        } catch (err) {
            throw err;
        }
    }

    getLatestSensorValStr = (index) => {
        if (this.maintenanceData[index] !== undefined) {
            const tideValue = this.maintenanceData[index].sensor_data_value;
            const originTimeZone = this.state.deployments[index].timezone;
            const browserTimeZone = this.state.timeZoneBrowser;
            const utcTime = moment.utc(this.maintenanceData[index].sensor_data_date, 'YYYY-MM-DD HH:mm:ss');
            const originTime = utcTime.tz(originTimeZone).format('YYYY-MM-DD HH:mm:ss');
            const localTime = utcTime.tz(browserTimeZone).format('YYYY-MM-DD HH:mm:ss');
            if (this.props.timeZone !== true){
                if (isMobile){
                    return (<div >{originTime} - {tideValue}m</div>)
                }
                return (<div >{originTime} - {tideValue}m <Chip size="small" label={originTimeZone} /></div>)
            }
            if (isMobile){
                return (<div >{localTime} - {tideValue}m</div>)
            }
            return (<div >{localTime} - {tideValue}m <Chip size="small" label={browserTimeZone} /></div>)
        } else {
            return 'Not Available';
        }
    };

    getLatestBatteryValStr = (index) => {
        if (this.maintenanceData[index] !== undefined) {
            const originTimeZone = this.state.deployments[index].timezone;
            const browserTimeZone = this.state.timeZoneBrowser;
            const utcTime = moment.utc(this.maintenanceData[index].battery_data_date, 'YYYY-MM-DD HH:mm:ss');
            const originTime = utcTime.tz(originTimeZone).format('YYYY-MM-DD HH:mm:ss');
            const localTime = utcTime.tz(browserTimeZone).format('YYYY-MM-DD HH:mm:ss');
            const batValue = this.maintenanceData[index].battery_data_value;
            if (this.props.timeZone !== true){
                if (isMobile){
                    return (<div>{originTime} - {batValue}V</div>)
                }
                return (<div>{originTime} - {batValue}V <Chip size="small" label={originTimeZone} /></div>)
            }
            if (isMobile){
                return (<div>{originTime} - {batValue}V</div>)
            }
            return (<div>{localTime} - {batValue}V <Chip size="small" label={browserTimeZone} /></div>)
        } else {
            return 'Not Available';
        }

    };

    customPanelStyle = {
        background: '#f7f7f7',
        borderRadius: 4,
        marginBottom: 24,
        border: 0,
        overflow: 'hidden',
    };

    convertTimezone = (time, index) => {
        const originTimeZone = this.state.deployments[index].timezone
        const browserTimeZone = this.state.timeZoneBrowser
        const utcTime = moment.utc(time, 'YYYY-MM-DD HH:mm:ss')
        const originTime = utcTime.tz(originTimeZone).format('YYYY-MM-DD HH:mm:ss')
        const localTime = utcTime.tz(browserTimeZone).format('YYYY-MM-DD HH:mm:ss')
        if (this.props.timeZone !== true) {

            return (`${originTime} - ${originTimeZone}`)
        }
        else{
            return (`${localTime} - ${browserTimeZone}`)
        }
    }

    text (index){
        return(
            <div>
                {/*<pre>{JSON.stringify(this.notification[index][0], null, 2) }</pre>*/}
                <Collapse>
                    {this.maintenanceData[index] !== undefined &&
                        <Panel header="Last Battery Change" key="0">
                            <Row>
                                <Col span={6}>
                                    <p style={{fontWeight: 'bold'}}>time</p>
                                </Col>
                                <Col span={18}>
                                     {this.maintenanceData[index] === undefined ?
                                         'NA'
                                         :
                                         this.convertTimezone(this.maintenanceData[index].last_battery_change_date, index)
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    <p style={{fontWeight: 'bold'}}>voltage</p>
                                </Col>
                                <Col span={18}>
                                     {this.maintenanceData[index] === undefined ?
                                         'NA'
                                         :
                                         `${this.maintenanceData[index].voltage_after_battery_change}V`
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    <p style={{fontWeight: 'bold'}}>days since change</p>
                                </Col>
                                <Col span={18}>
                                     {this.maintenanceData[index] === undefined ?
                                         'NA'
                                         :
                                         `${this.maintenanceData[index].days_since_battery_change} days`
                                    }
                                </Col>
                            </Row>
                        </Panel>
                    }
                    {this.notification[index] !== undefined &&
                        <Panel header="Last Disconnection Warning" key="1">
                            <Divider orientation="left">Sensor Data</Divider>
                            <Row>
                                <Col span={6}>
                                    <p style={{fontWeight: 'bold'}}>time</p>
                                </Col>
                                <Col span={18}>
                                    {this.notification[index][0].SW_D_time === '1-01-01 00:00:00' ?
                                        'NA'
                                        :
                                        this.convertTimezone(this.notification[index][0].SW_D_time, index)
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    <p style={{fontWeight: 'bold'}}>recipients</p>
                                </Col>
                                <Col span={18}>
                                    {this.notification[index][0].SW_D_time === '1-01-01 00:00:00' ? 'NA' : this.notification[index][0].SW_D_recipients}
                                </Col>
                            </Row>
                            <Divider orientation="left">Battery Data</Divider>
                            <Row>
                                <Col span={6}>
                                    <p style={{fontWeight: 'bold'}}>time</p>
                                </Col>
                                <Col span={18}>
                                    {this.notification[index][0].SW_D_time === '1-01-01 00:00:00' ?
                                        'NA'
                                        :
                                        this.convertTimezone(this.notification[index][0].BW_D_time, index)
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    <p style={{fontWeight: 'bold'}}>recipients</p>
                                </Col>
                                <Col span={18}>
                                    {this.notification[index][0].BW_D_time === '1-01-01 00:00:00' ? 'NA' : this.notification[index][0].BW_D_recipients}
                                </Col>
                            </Row>
                        </Panel>
                    }
                    {this.notification[index] !== undefined &&
                        <Panel header="Low Battery Warning" key="2">
                            <Divider orientation="left">Latest</Divider>
                            <Row>
                                <Col span={6}>
                                    <p style={{fontWeight: 'bold'}}>time</p>
                                </Col>
                                <Col span={18}>
                                    {this.convertTimezone(this.notification[index][0].BW_LV_time, index)}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    <p style={{fontWeight: 'bold'}}>voltage</p>
                                </Col>
                                <Col span={18}>
                                    {this.notification[index][0].BW_LV_value}V
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    <p style={{fontWeight: 'bold'}}>threshold voltage</p>
                                </Col>
                                <Col span={18}>
                                    {this.notification[index][0].BW_LV_minVolt}V
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    <p style={{fontWeight: 'bold'}}>recipients</p>
                                </Col>
                                <Col span={18}>
                                    {this.notification[index][0].BW_LV_recipients}
                                </Col>
                            </Row>
                        </Panel>
                    }
                    {this.notification[index] !== undefined && this.notification[index][0].SW_T_time !== '1-01-01 00:00:00' &&
                        <Panel header="Tide Warning" key="3">
                            <Divider orientation="left">latest</Divider>
                            <Row>
                                <Col span={8}>
                                    <p style={{fontWeight: 'bold'}}>time</p>
                                </Col>
                                <Col span={16}>
                                    {this.convertTimezone(this.notification[index][0].SW_T_time, index)}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <p style={{fontWeight: 'bold'}}>low tide threshold</p>
                                </Col>
                                <Col span={16}>
                                    {this.notification[index][0].SW_T_lowTide === '-100' ? 'NA' : this.notification[index][0].SW_T_lowTide }
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <p style={{fontWeight: 'bold'}}>high tide threshold</p>
                                </Col>
                                <Col span={16}>
                                    {this.notification[index][0].SW_T_highTide === '100' ? 'NA' : this.notification[index][0].SW_T_highTide }
                                </Col>
                            </Row>
                             <Row>
                                <Col span={8}>
                                    <p style={{fontWeight: 'bold'}}>value</p>
                                </Col>
                                <Col span={16}>
                                     {this.notification[index][0].SW_T_value}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <p style={{fontWeight: 'bold'}}>recipients</p>
                                </Col>
                                <Col span={16}>
                                     {this.notification[index][0].SW_T_recipients}
                                </Col>
                            </Row>
                        </Panel>
                    }
                    {this.notification[index] !== undefined && this.notification[index][0].AW_time !== '1-01-01 00:00:00' &&
                        <Panel header="Admin Warning" key="4">
                            <Divider orientation="left">latest</Divider>
                            <Row>
                                <Col span={8}>
                                    <p style={{fontWeight: 'bold'}}>time</p>
                                </Col>
                                <Col span={16}>
                                    {this.convertTimezone(this.notification[index][0].AW_time, index)}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <p style={{fontWeight: 'bold'}}>recipients</p>
                                </Col>
                                <Col span={16}>
                                    {this.notification[index][0].AW_recipients}
                                </Col>
                            </Row>
                        </Panel>
                    }
                    {this.notification[index] !== undefined && this.maintenanceData[index] !== undefined &&
                        <Spin />
                    }
                </Collapse>
            </div>
        )
    }

    render() {
        return (
            <div>
                <MetaTags>
                    <meta name="description" content="Tide gauge monitoring system" />
                    <meta property="og:title" content="EGS Sensor Monitor" />
                    <meta property="og:image" content={EGS_Logo} />
                </MetaTags>

                {/*{(true) ? (*/}
                {(this.maintenanceData.length === this.state.deployments.length) ? (
                    <List
                        grid={{
                            gutter: 14,
                            xs: 1,
                            sm: 1,
                            md: 1,
                            lg: 2,
                            xl: 3,
                            xxl: 4,
                        }}
                        dataSource={this.state.deployments}
                        renderItem={(item, index) => (
                            <List.Item>
                                <div
                                    style={{
                                        backgroundColor: `#${this.state.colourPalette[index]}`,
                                        padding: '10px 10px',
                                        borderRadius: '5px 5px 0px 0px'
                                    }}
                                >
                                    <Row style={{align: 'middle'}}>
                                        <Col span={16}>
                                            <h6 style={{color: 'white'}}>
                                                {item.deployment_device.title_amended}
                                            </h6>
                                        </Col>
                                        <Col span={8}>
                                            <BatteryData percentage={this.maintenanceData[index] !== undefined ? (this.maintenanceData[index].percentage) : (0)}/>
                                        </Col>
                                    </Row>
                                </div>
                                {/*{this.maintenanceData[index] !== undefined ? (*/}

                                    <div
                                        style={{
                                            backgroundColor: `#${this.state.colourPalette[index]}`,
                                            padding: '10px 10px',
                                            borderRadius: '0px 0px 5px 5px',
                                            opacity: 0.8,
                                            color: 'white'
                                        }}
                                    >
                                        <Skeleton active loading={this.maintenanceData[index] === undefined}>
                                        <Row style={{align: 'left'}}>
                                            <Col span={7}>
                                                <p> Status: </p>
                                            </Col>
                                            <Col span={17}>
                                                {this.maintenanceData[index] !== undefined ? (this.maintenanceData[index].status_sensor) : ('Not Available')}
                                            </Col>
                                        </Row>
                                        <div style={{margin: '0px 0px 0px 0px'}}>
                                            <div
                                                style={{
                                                padding: '0px 0px 0px 0px',
                                                borderRadius: '5px 5px 0px 0px',
                                                backgroundColor: 'white',
                                                width: 'w-50'
                                                }}
                                            >
                                                <p className='mb-0' style={{color: 'black', textAlign: 'center'}}> Values
                                                    from Tide Gauge </p>
                                            </div>
                                            <div
                                                style={{
                                                border: '2px solid white',
                                                padding: '10px 10px 10px 10px',
                                                borderRadius: '0px 0px 5px 5px',
                                                marginBottom: '10px'
                                                }}
                                            >
                                                <Row style={{align: 'left'}}>
                                                    <Col span={6}>
                                                        <p> Sensor: </p>
                                                    </Col>
                                                    <Col span={18}>
                                                        {this.getLatestSensorValStr(index)}
                                                    </Col>
                                                </Row>
                                                <Row style={{align: 'left'}}>
                                                    <Col span={6}>
                                                        <p> Battery: </p>
                                                    </Col>
                                                    <Col span={18}>
                                                        {this.getLatestBatteryValStr(index)}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <Row style={{align: 'left'}}>
                                            <Col span={7}>
                                                <p> Time left for replacement: </p>
                                            </Col>
                                            <Col span={17}>
                                                {this.maintenanceData[index] !== undefined ? (this.maintenanceData[index].time_left) : ('Not Available')}
                                            </Col>
                                        </Row>
                                        {!isMobile &&
                                            <div>
                                                <Collapse bordered={false} defaultActiveKey={['0']}>
                                                    <Panel header="Logs for Notifications" key="1" style={this.customPanelStyle}>
                                                        {this.text(index)}
                                                    </Panel>
                                                </Collapse>
                                            </div>
                                        }
                                        </Skeleton>
                                    </div>
                            </List.Item>
                        )}
                    />
                ) : (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <WaveLoading />
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.token
    }
};

export default connect(mapStateToProps)(Maintenance);