import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import ReactMapGL, { Marker, NavigationControl} from "react-map-gl";
import {Card, Button, Row, Col} from "antd";
import { token, styles } from "../config";
import { Link } from "react-router-dom";
import "mapbox-gl/dist/mapbox-gl.css";
import MdHeart from "react-ionicons/lib/IosStats";
import HKO from "react-ionicons/lib/IosCloudy"
import MdWarning from "react-ionicons/lib/MdWarning";
import MdBatteryDead from "react-ionicons/lib/MdBatteryDead"
import { Popup } from "semantic-ui-react";
import { FaCarBattery, FaWater } from 'react-icons/fa'
import {GrShift} from 'react-icons/gr'
import {AiFillClockCircle} from 'react-icons/ai'
import { API } from "../config";
import DeploymentData from "../components/DeploymentData";
import {WaveLoading} from "react-loadingg";

import ReactNotification from 'react-notifications-component'
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import {isMobile} from 'react-device-detect';
import MetaTags from 'react-meta-tags';
import EGS_Logo from '../assets/EGS_Logo.png'
import moment from "moment-timezone";


class MapView extends React.Component {

    mapRef = React.createRef();

    state = {
        viewport: {
            width: "100%",
            height: this.getWindowHeight(),
            latitude: parseFloat(localStorage.getItem('latitude')),
            longitude: parseFloat(localStorage.getItem('longitude')),
            zoom: isMobile? 8.5 : 10,
            maxZoom: 15,
            bearing: 0,
            pitch: 0
        },
        project_deployments: [],
        batterydata: [],
        sensorVal: [],
        isUpdate: true,
        loading: false,
        active: false,
        timeZoneBrowser: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    isSensorData = false;
    sensordata = [];
    _isMounted = false;
    intervalID = [];

    componentDidMount() {
        this._isMounted = true;
        if (this.props.token !== null) {
            this.getDeploymentData();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.token !== prevProps.token && this._isMounted && !this.isSensorData) {
            this.getDeploymentData();
        }
        // if ((this.state.project_deployments.length !== 0 && this.sensordata.length === 0 && !this.isSensorData)||(this.props.timeZone !== prevProps.timeZone) || !this.state.isUpdate) {
        if ((this.props.timeZone !== prevProps.timeZone) || ((this.state.project_deployments.length !== 0 && this.sensordata.length === 0 && !this.isSensorData) && !this.state.isUpdate)) {
            this.getSensorData();
        }
        // if
    }

    getWindowHeight () {
        if (isMobile){
            return ("calc(100vh - 90px)")
        }
        return ("calc(100vh - 167px)")
    }

    convertTimezone = (time, index) => {
        const originTimeZone = this.state.project_deployments[index].timezone
        const browserTimeZone = this.state.timeZoneBrowser
        const utcTime = moment.utc(time, 'YYYY-MM-DD HH:mm:ss')
        const originTime = utcTime.tz(originTimeZone).format('YYYY-MM-DD HH:mm:ss')
        const localTime = utcTime.tz(browserTimeZone).format('YYYY-MM-DD HH:mm:ss')
        if (this.props.timeZone !== true) {

            return (`${originTime} - ${originTimeZone}`)
        }
        else{
            return (`${localTime} - ${browserTimeZone}`)
        }
    }


    getDeploymentData() {
        try {
            this.setState({ loading: true }, () => {
                axios.defaults.headers = {
                    "Content-Type": "application/json",
                    Authorization: `Token ${localStorage.getItem("token")}`,
                };
                axios.get(API.deployments).then((res) => {
                    this.setState({
                        loading: false,
                        project_deployments: res.data,
                        isUpdate: false
                    });
                });
            });
        } catch (err) {
        throw err;
        }
    }

    _updateViewport = (viewport) => {
        this.setState({ viewport });
    };

    getSensorData() {
        this.isSensorData = true;
        this.state.project_deployments.map((sensor, index) => (
            this.getSensorDataIndividual(sensor, index)
        ));
        setTimeout(()=>{
            this.setState({
                isUpdate: true,
                active: true
            })
        }, 1000);
    }
    componentWillUnmount() {
        for (let i = 0; i < this.intervalID.length; i++) {
            clearTimeout(this.intervalID[i]);
        }
        this.intervalID = [];
    }
    getDelayFromDate = (dateValue, status) => {
        if (status === true){
            const lastTime_sensor = new Date(moment.utc(dateValue, 'YYYY-MM-DD HH:mm:ss'));
            const nowTime = new Date(moment.utc());
            const delay_sensor = (new Date(lastTime_sensor).getTime() - nowTime.getTime()) + 301000;
            return delay_sensor;
        }
        const lastTime_sensor = new Date(moment.utc(dateValue, 'YYYY-MM-DD HH:mm:ss'));
        const nowTime = new Date(moment.utc());
        const delay_sensor = (new Date(lastTime_sensor).getTime() - nowTime.getTime()) + 601000;
        return delay_sensor;

    };

    checkIfWorking = (index) => {
        const utcTime_now = moment.utc();
        const utcTime_lastTime = moment.utc(this.sensordata[index].TG_time, 'YYYY-MM-DD HH:mm:ss')
        const differenceSen = Math.trunc(utcTime_now.diff(utcTime_lastTime) / 60000);
        return differenceSen <= 10;
    };

    checkIfBatteryLow = (index) =>{
        const batteryVal = parseFloat(this.sensordata[index].voltage)
        const minVolt = parseFloat(this.sensordata[index].bat_min)
        return batteryVal < minVolt;
    }

    getSensorDataIndividual = (sensor, index) => {
        const non_egs_sensor = this.state.project_deployments[index].deployment_device.ownership_state
        let MA = 'False';
        if (this.state.project_deployments[index].deployment_device.modem.manufacturer_name === 'MTX'){
            MA = 'True';
        }
        axios.get(`${API.singleSensorData}${sensor.deployment_device.token}/?MA=${MA}`)
            .then(res => {
                if (res.data) {
                    this.sensordata[index] = res.data[0];
                    if (!isMobile && this.state.active && non_egs_sensor === true){
                        if (this.checkIfWorking(index)){
                            if (this.checkIfBatteryLow(index) && this.props.isAdmin === 'true'){
                                store.addNotification({
                                    title: `${this.state.project_deployments[index].deployment_device.title_amended} (LOW BATTERY)`,
                                    message:  `${this.sensordata[index].tide.toFixed(3)}m - \n${this.sensordata[index].voltage}V`,
                                    type: "warning",
                                    insert: "top",
                                    showIcon: true,
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 100000,
                                        onScreen: true
                                    }
                                })
                            }
                            else{
                                store.addNotification({
                                    title: `${this.state.project_deployments[index].deployment_device.title_amended}`,
                                    message:  `${this.sensordata[index].tide.toFixed(3)}m - \n${this.sensordata[index].voltage}V`,
                                    type: "success",
                                    insert: "bottom",
                                    showIcon: true,
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 5000
                                    }
                                });
                            }
                        }
                        else{
                            store.addNotification({
                                title: `${this.state.project_deployments[index].deployment_device.title_amended}`,
                                message:  `NOT WORKING - attempting again in 5 mins`,
                                type: "danger",
                                insert: "top",
                                showIcon: true,
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 300000,
                                    onScreen: true
                                }
                            });
                        }
                    }
                    else{
                        if (!isMobile && non_egs_sensor === false){
                            store.addNotification({
                                title: `${this.state.project_deployments[index].deployment_device.title_amended}`,
                                message:  `${this.sensordata[index].tide.toFixed(3)}m`,
                                type: "success",
                                insert: "bottom",
                                showIcon: true,
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 5000
                                }
                            });
                        }
                    }
                    const delay = this.getDelayFromDate(this.sensordata[index].TG_time, non_egs_sensor);
                    clearTimeout(this.intervalID[index]);
                    if (delay > 0 && delay < 360000) {
                        this.intervalID[index] = setTimeout(() => {
                            this.getSensorDataIndividual(sensor, index);
                        }, delay);
                    } else {
                        if (non_egs_sensor === true){
                            this.intervalID[index] = setTimeout(() => {
                                this.getSensorDataIndividual(sensor, index);
                            }, 301000);
                        }
                        else{
                            this.intervalID[index] = setTimeout(() => {
                                this.getSensorDataIndividual(sensor, index);
                            }, 301000);
                        }
                    }
                } else {
                    this.intervalID[index] = setTimeout(() => {
                        this.getSensorDataIndividual(sensor, index);
                    }, 301000);
                }

            }).catch( error => {
                if (!isMobile && this.state.active){
                    store.addNotification({
                        title: `${this.state.project_deployments[index].deployment_device.title_amended}`,
                        message:  `Backend is not responding please wait 5 minutes for the system to retry`,
                        type: "danger",
                        insert: "top",
                        showIcon: true,
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 1000,
                            onScreen: true
                        }
                    });
                }
                console.log(error)
            });
    }

    statusMarker = (index) => {
        if (this.state.project_deployments.length !==0){
            const non_egs_sensor = this.state.project_deployments[index].deployment_device.ownership_state
            if (this.sensordata[index] !== undefined){
                const utcTime_now = moment.utc();
                const utcTime_lastTime = moment.utc(this.sensordata[index].TG_time, 'YYYY-MM-DD HH:mm:ss')
                const differenceSen = Math.trunc(utcTime_now.diff(utcTime_lastTime) / 60000);
                const batteryVal = parseFloat(this.sensordata[index].voltage)
                const minVolt = parseFloat(this.sensordata[index].bat_min)

                if (differenceSen > 15){
                return (
                    <Button type="link">
                        <MdWarning shake={true} fontSize="30px" color="red" />
                    </Button>
                    )
                }
                else if (batteryVal < minVolt){
                    if (this.props.isAdmin && non_egs_sensor){
                        // console.log('low battery')
                        return (
                            <Button type="link">
                                <MdBatteryDead shake={true} fontSize="30px" color="red" />
                            </Button>
                        )
                    }
                }
                if (non_egs_sensor === false){
                    // console.log(non_egs_sensor)
                    return (
                        <Button type="link">
                            <HKO fontSize="20px" color="#8934eb" beat={true} />
                        </Button>
                    )
                }
                return (
                    <Button type="link">
                        <MdHeart fontSize="20px" color="green" beat={true} />
                    </Button>
                )
            }
            if (non_egs_sensor === false){
                return (
                <Button type="link">
                    <HKO fontSize="20px" color="grey" beat={false} />
                </Button>
            )
            }
            return (
                <Button type="link">
                    <MdHeart fontSize="20px" color="grey" beat={false} />
                </Button>
            )
        }
    };

    render() {
        return (
            <div>
                <MetaTags>
                    <meta name="description" content="Tide gauge monitoring system" />
                    <meta property="og:title" content="EGS Sensor Monitor" />
                    <meta property="og:image" content={EGS_Logo} />
                    <meta http-equiv="pragma" content="no-cache" />
                    <meta http-equiv="expires" content="0" />
                    <meta http-equiv="cache-control" content="no-cache" />
                </MetaTags>
                {this.state.loading ?
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <WaveLoading />
                        {/*{console.log(isMobile)}*/}
                    </div>
                    :
                    <Card
                        bordered={false}
                        bodyStyle={{
                            backgroundColor: "#bae7ff",
                            overflow: "hidden",
                            padding: "0px 0px",
                        }}
                    >
                        <ReactNotification />
                        {/*{console.log(localStorage.getItem('timeZone'))}*/}
                        <div
                            style={{position: 'absolute',
                                bottom: '50px',
                                left: 'calc(100vw - 200px)',
                                zIndex:1,
                                backgroundColor: "rgb(255,255,255)",
                                padding: "5px 5px 5px 5px",
                                borderRadius: '3px 3px 3px 3px'
                            }}
                        >
                            {/*<a href={logo} download="logo.svg"> Download Here </a>*/}
                            <Row gutter={16} style={{textAlign: 'center', color: 'blue', fontWeight: 'bold'}}>
                                Legend
                            </Row>
                            <Row gutter={16} style={{ color: 'black'}}>
                                <Col span={4}>
                                    <MdHeart fontSize="20px" color="green" beat={true} />
                                </Col>
                                <Col span={20}>
                                    Working
                                </Col>
                            </Row>
                            <Row gutter={16} style={{ color: 'black'}}>
                                <Col span={4}>
                                    <MdWarning shake={true} fontSize="20px" color="red" />
                                </Col>
                                <Col span={20}>
                                    Not working
                                </Col>
                            </Row>
                            <Row gutter={16} style={{ color: 'black'}}>
                                <Col span={4}>
                                    <HKO fontSize="20px" color="#8934eb" beat={true} />
                                </Col>
                                <Col span={20}>
                                    HKO Sensor
                                </Col>
                            </Row>
                            {this.props.isAdmin &&
                                <div>
                                    <Row gutter={16} style={{ color: 'black'}}>
                                        <Col span={4}>
                                            <MdHeart fontSize="20px" color="grey" beat={false} />
                                        </Col>
                                        <Col span={20}>
                                            Data unavailable
                                        </Col>
                                    </Row>
                                    <Row gutter={16} style={{ color: 'black'}}>
                                        <Col span={4}>
                                            <MdBatteryDead shake={true} fontSize="20px" color="red" />
                                        </Col>
                                        <Col span={20}>
                                            Battery low
                                        </Col>
                                    </Row>
                                </div>
                            }

                        </div>
                        {this.state.project_deployments.length !== 0 ? (
                            <ReactMapGL
                                ref={this.mapRef}
                                {...this.state.viewport}
                                mapStyle={styles.custom}
                                // onViewportChange={(viewport) => this.setState({viewport})}
                                onViewportChange={this._updateViewport}
                                mapboxApiAccessToken={token}
                            >
                                {this.state.project_deployments.map((deploy, index) => (
                                    <Marker
                                        key={deploy.id}
                                        latitude={parseFloat(deploy.latitude)}
                                        longitude={parseFloat(deploy.longitude)}
                                        offsetLeft={-20}
                                        offsetTop={-10}
                                    >
                                        <Link to={"/deployments/" + deploy.id + "/"}>
                                            <Popup
                                                content={
                                                    <div
                                                        style={{
                                                            padding: "3px 3px 3px 3px",
                                                            backgroundColor: "#001529",
                                                        }}
                                                    >
                                                        <Card
                                                            title={
                                                                <div style={{ color: "white", textAlign: "center"}}>
                                                                    {deploy.deployment_device.title_amended}
                                                                    {/*{console.log(deploy.deployment_device.ownership_state)}*/}
                                                                    { this.sensordata[index] !== undefined ? (
                                                                        <div>
                                                                            {this.props.isAdmin &&  deploy.deployment_device.ownership_state ? (
                                                                                <div
                                                                                    style={{
                                                                                        fontSize:"12px",
                                                                                        fontWeight: 'normal',
                                                                                        backgroundColor: "rgb(46,177,255)",
                                                                                        padding: "3px 3px 3px 3px",
                                                                                        borderRadius: '3px 3px 3px 3px',
                                                                                        textAlign: 'center'
                                                                                    }}>
                                                                                        <Row gutter={0}>
                                                                                            <Col span={12}>
                                                                                                <FaWater style={{fontSize:"12px"}}/> {parseFloat(this.sensordata[index].tide).toFixed(3)}m
                                                                                            </Col>
                                                                                            <Col span={12}>
                                                                                                <FaCarBattery style={{fontSize:"12px"}}/> {parseFloat(this.sensordata[index].voltage).toFixed(3)}V
                                                                                            </Col>
                                                                                            <Col span={24}>
                                                                                                <AiFillClockCircle style={{fontSize:"12px"}}/> {this.convertTimezone(this.sensordata[index].TG_time, index)}
                                                                                            </Col>
                                                                                        </Row>
                                                                                </div>
                                                                            ): null}
                                                                        </div>
                                                                    ): (
                                                                        <div style={{fontSize:"12px", fontWeight: 'normal', backgroundColor: "rgb(46,177,255)", padding: "3px 3px 3px 3px", borderRadius: '3px 3px 3px 3px' }}>
                                                                            Data not available
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            }
                                                            bordered={false}
                                                            bodyStyle={{
                                                                backgroundColor: "rgba(24,144,255,0.4)",
                                                                border: 0,
                                                            }}
                                                            headStyle={{
                                                                backgroundColor: "rgba(24,144,255,1)",
                                                                border: 0,
                                                            }}
                                                        >
                                                            {this.props.isAdmin &&  deploy.deployment_device.ownership_state ? (
                                                                    <div style={{ padding: "5px 5px" }}>
                                                                        <DeploymentData data={deploy}  sensorValues={this.sensordata[index]}/>
                                                                    </div>
                                                                ):(
                                                                    <div>
                                                                        {this.sensordata[index] !== undefined ? (
                                                                            <div>
                                                                                <Row gutter={16}>
                                                                                    <Col span={3}>
                                                                                        <FaWater style={{fontSize: '20px', color: '#2e2e28'}}/>
                                                                                    </Col>
                                                                                    <Col span={21}>
                                                                                        {parseFloat(this.sensordata[index].tide).toFixed(3)}m
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row gutter={16}>
                                                                                    <Col span={3}>
                                                                                        <AiFillClockCircle style={{fontSize: '20px', color: '#2e2e28'}}/>
                                                                                    </Col>
                                                                                    <Col span={21}>
                                                                                        {this.convertTimezone(this.sensordata[index].TG_time, index)}
                                                                                    </Col>
                                                                                </Row>
                                                                                {deploy.deployment_device.offset !== 0.0 &&
                                                                                    <Row gutter={16}>
                                                                                        <Col span={3}>
                                                                                            <GrShift style={{fontSize: '20px', color: '#2e2e28'}}/>
                                                                                        </Col>
                                                                                        <Col span={21}>
                                                                                            {deploy.deployment_device.offset}m (offset applied)
                                                                                        </Col>
                                                                                    </Row>
                                                                                }
                                                                            </div>
                                                                        ): null}
                                                                    </div>
                                                                )
                                                            }
                                                        </Card>
                                                    </div>
                                                }
                                                trigger={
                                                    this.statusMarker(index)
                                                }
                                            />
                                        </Link>
                                    </Marker>
                                ))}
                                <div
                                    style={{
                                        position: "absolute",
                                        left: 0,
                                        marginLeft: "10px",
                                        marginTop: "5px",
                                    }}
                                >
                                    <NavigationControl onViewportChange={this._updateViewport} />
                                </div>
                            </ReactMapGL>
                        ) : null}
                    </Card>
                }
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.token,
    };
};

export default connect(mapStateToProps, null)(MapView);
