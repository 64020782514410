import React, {Component} from 'react';
import {connect} from 'react-redux';
import 'antd/dist/antd.css';
import * as actions from './store/actions/auth'
import Container from "./components/Container";


class App extends Component {

    componentDidMount() {
        this.props.onTryAutoSignup();
    }

    render() {
        return (
                <Container {...this.props}/>
        );
    }

}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.token !== null,
        isLoggedIn: state.loggedIn,
        isAdmin: state.isAdmin,
        isModemAccessAllowed: state.isModemAccessAllowed,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState())
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(App);
